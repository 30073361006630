import { graphqlClient } from './apolloSetupCore';

export const clearCurrentSession = () => {
  // Remove all data from the store (Unlike resetStore, clearStore will not refetch any active/pending queries)
  graphqlClient.clearStore().then(() => {
    localStorage.removeItem('graphql_session');
    localStorage.removeItem('allowed');
    sessionStorage.removeItem('query');
    localStorage.removeItem('search_query');
    localStorage.removeItem('m_s_q_n');
    localStorage.removeItem('m_s_q_l');
    localStorage.removeItem('banner');
    localStorage.removeItem('sort_option');
    localStorage.removeItem('enquiry_filters');
    localStorage.removeItem('permissions');
    localStorage.removeItem('_ID_check');
    localStorage.removeItem('g_s_q');
    localStorage.removeItem('orders_active_tab');
    localStorage.removeItem('allow_customer_self_serve');
    localStorage.removeItem('request_submitted');
    localStorage.removeItem('filter_certificate_numbers');
    localStorage.removeItem('dashboard_mode');
  });
};

export const clearOnLogin = () => {
  localStorage.removeItem('orders_active_tab');
  localStorage.removeItem('allow_customer_self_serve');
};
