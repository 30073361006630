import { useState } from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import { useMutation } from '@apollo/client';
import { Button as NivodaUiButton } from '@nivoda/ui';
import { capitalize, flatten, isEmpty, uniq } from 'lodash';
import { CheckboxMedia, CustomDatePicker } from '@nivoda/forms';

import {
  Diamond,
  formatDate,
  CustomTrans,
  HideOnReview,
  isActiveSet,
} from 'helpers';
import { theme } from 'fabric/theme';
import { Button } from 'fabric/components';
import { Icon } from 'fabric/resources/icon';
import { CopyInformation } from 'UI/NewCopy';
import { useErrorWrapper } from 'fabric/hooks';
import { graphqlClient } from 'graphql/factory';
import { getUserReviewStatus } from 'utils/user';
import { useUserContext } from 'context/userContext';
import { compareDateFunction, isMobile } from 'utils';
import { ExchangeRate } from 'graphql/queries/common';
import { StatusPill } from 'components/ui/StatusPill';
import { getPaymentTitleOnly } from 'App/Checkout/helper';
import { defaultCxMeleeReqStateRX } from 'graphql/factory/reactiveVar';
import CopyToClipboard from 'NewDashboard/components/copy-to-clipboard';
import { CUSTOMER_CANCEL_DIAMOND_REQUEST } from 'App/DiamondRequest/utils';
import { CertWithIconV2, DiamondTitle, Price, PriceExchanged } from 'Elements';
import { statuses } from 'Admin/components/RequestAndEnquiries/MeleeSpecialRequest/constants';

import MeleeReqStatusPill from './MeleeRequests/MeleeReqStatusPill';
import { RequestPriceWrapper, WrapColumn } from './DiamondRequests/styles';
import { CancelDiamondRequestPopup } from './DiamondRequests/CancelDiamondRequest';
import { useDiamondRequestsFiltersStore } from './DiamondRequests/filters/useDiamondRequestsFiltersStore';

// Melee Requests
export const getMeleeReqFilterConfig = (t, listOfStatus) => [
  {
    id: 'cx_status_key',
    title: t('status'),
    content: listOfStatus,
    ComponentToRender: CheckboxMedia,
    contentData: null,
    contentType: 'CHECKBOX',
  },
  {
    id: 'date',
    title: t('date'),
    content: [],
    attrs: ['label', 'id'],
    ComponentToRender: NivodaUiButton,
    contentType: 'BUTTON',
    shouldCloseOnSelect: false,
    subContent: {
      title: '',
      content: [
        {
          id: 'from',
          dateType: 'start_date',
          placeholder: t('from'),
        },
        {
          id: 'to',
          dateType: 'end_date',
          placeholder: t('to'),
        },
      ],
      contentType: 'DATE_PICKER',
      component: CustomDatePicker,
      attrs: ['placeholder', 'dateType'],
    },
  },
];

export function meleeFilterBy(reqs = [], query) {
  const hasQuery = isActiveSet(query, defaultCxMeleeReqStateRX);
  if (!hasQuery) {
    return reqs;
  }

  const queryTextArr =
    query?.text === ''
      ? []
      : query?.text
          ?.split(',') // search texts are separated out by comma
          .map((t) => t.trim()) // trim spaces
          .filter(Boolean); // filter only non empty strings

  const searchResult = flatten(
    queryTextArr?.length > 0
      ? queryTextArr?.map((q) =>
          reqs.filter((i) =>
            i?.orderReqNum?.toLowerCase()?.includes(q?.toLowerCase())
          )
        )
      : [...reqs]
  );

  return uniq([...searchResult]);
}

export const manipulateMeleeReqQuery = (object) => {
  const newObj = { ...object };

  Object.keys(newObj).forEach((key) => {
    // delete search text since it is FE search
    if (key === 'text') delete newObj[key];

    // remove empty data in date object since date is an object
    if (key === 'date') {
      const dataObj = manipulateMeleeReqQuery(newObj.date);
      if (isEmpty(dataObj)) delete newObj[key];
      else if (dataObj?.start_date && dataObj?.end_date)
        newObj.date = {
          from: dataObj?.start_date,
          to: dataObj?.end_date,
        };
    }

    // remove empty data
    if (isEmpty(newObj[key])) delete newObj[key];
  });

  return newObj;
};

export const meleeReqTableColumns = (t) => [
  {
    name: t('request_number'),
    sortable: false,
    selector: 'requestNo',
    id: 'reqNum',
  },
  {
    name: t('melee_type'),
    sortable: false,
    selector: 'meleeType',
    id: 'meleeType',
  },
  {
    name: t('melee_info'),
    sortable: false,
    selector: 'meleeInfo',
    id: 'meleeInfo',
  },
  {
    name: t('comments'),
    sortable: false,
    selector: 'comments',
    id: 'comments',
  },
  {
    name: t('status'),
    sortable: false,
    selector: 'statusPill',
    id: 'status',
  },
  {
    name: t('date_requested'),
    sortable: true,
    selector: 'requestedDate',
    id: 'requestedDate',
    sortFunction: (a, b) => {
      const requestDateA = a?.request?.created_at;
      const requestDateB = b?.request?.created_at;

      return compareDateFunction(requestDateA, requestDateB, true);
    },
  },
];

export const MeleeReqCxStatuses = {
  OPEN: {
    value: 'OPEN',
    label: 'Requested',
    valueForFilter: 'REQUESTED',
    iconColor: theme.palette.Neutrals400,
    textColor: theme.palette.Neutrals700,
    showInFilter: true,
  },
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    label: 'In progress',
    valueForFilter: 'IN_PROGRESS',
    iconColor: theme.palette.blue600,
    textColor: theme.palette.blue600,
    showInFilter: true,
  },
  INTERNAL_FOLLOW_UP: {
    value: 'INTERNAL_FOLLOW_UP',
    label: 'In progress',
    iconColor: theme.palette.blue600,
    textColor: theme.palette.blue600,
    showInFilter: false,
  },
  QUOTE_SENT: {
    value: 'QUOTE_SENT',
    label: 'Quoted',
    valueForFilter: 'QUOTED',
    iconColor: theme.palette.violet600,
    textColor: theme.palette.violet600,
    badgeColor: theme.palette.violet50,
    iconName: 'tag',
    showInFilter: true,
  },
  REJECTED: {
    value: 'REJECTED',
    label: 'Rejected',
    valueForFilter: 'REJECTED',
    iconColor: theme.palette.red600,
    textColor: theme.palette.red600,
    showInFilter: true,
  },
  ACCEPTED: {
    value: 'ACCEPTED',
    label: 'Confirmed',
    valueForFilter: 'CONFIRMED',
    iconColor: theme.palette.greenNew600,
    textColor: theme.palette.greenNew600,
    showInFilter: true,
  },
  ORDER_PLACED: {
    value: 'ORDER_PLACED',
    label: 'Confirmed',
    iconColor: theme.palette.greenNew600,
    textColor: theme.palette.greenNew600,
    showInFilter: false,
  },
  EXPIRED: {
    value: 'EXPIRED',
    label: 'Expired',
    valueForFilter: 'EXPIRED',
    iconColor: theme.palette.amber600,
    textColor: theme.palette.amber600,
    showInFilter: true,
  },
  CANCELLED: {
    value: 'CANCELLED',
    label: 'Cancelled',
    valueForFilter: 'CANCELLED',
    iconColor: theme.palette.Neutrals400,
    textColor: theme.palette.Neutrals700,
    showInFilter: true,
  },
};

export const mapMeleeRequests = (reqs, t) => {
  return reqs?.map((req) => {
    let pendingQuotesLen = 0,
      orderedQuotesLen = 0;

    req?.OrderRequests?.forEach((quote) => {
      if (quote?.status === statuses?.PENDING_CONFIRMATION)
        pendingQuotesLen += 1;
      if (quote?.status === statuses?.ORDER_PLACED) orderedQuotesLen += 1;
    });

    const status = req?.status || MeleeReqCxStatuses?.REQUESTED?.value;
    let pillInfo = null;
    if (status === MeleeReqCxStatuses?.QUOTE_SENT?.value && pendingQuotesLen)
      pillInfo = `${pendingQuotesLen} quote${
        pendingQuotesLen === 1 ? '' : 's'
      }`;
    else if (
      status === MeleeReqCxStatuses?.ORDER_PLACED?.value &&
      orderedQuotesLen
    )
      pillInfo = `${orderedQuotesLen} order${
        orderedQuotesLen === 1 ? '' : 's'
      } placed`;

    return {
      id: req?.id,
      status,
      orderReqNum: req?.order_request_number,
      requestNo: (
        <div className="reqNum">
          <span>{req?.order_request_number}</span>{' '}
          <CopyToClipboard
            showGreenTick
            iconWidth={16}
            iconHeight={16}
            iconName="copy"
            text={req?.order_request_number}
            iconColor={theme.palette.Neutrals500}
            hoverColor={theme.palette.Neutrals500}
          />
        </div>
      ),
      meleeType: capitalize(req?.stone_type),
      meleeInfo: (
        <div className="melee-info">
          {cx(
            req?.color === 'FANCY'
              ? req?.f_color ?? ''
              : req?.color?.replace('_', '-') ?? '',
            req?.clarity,
            capitalize(req?.shape)?.replaceAll('_', ' '),
            req?.cut
          )}
        </div>
      ),
      comments:
        req?.buyer_comment || req?.admin_comment ? (
          <div className="comments">
            {req?.buyer_comment || req?.admin_comment}
          </div>
        ) : (
          <div className="no-comments">{t('no_comments_added')}</div>
        ),
      statusPill: <MeleeReqStatusPill info={pillInfo} status={status} />,
      requestedDate: formatDate(req?.createdAt, null, 'MMM', 'MMM D YYYY'),
    };
  });
};

// Diamond Requests
export const mapRequestsData = (data, t, handleCancel) => {
  let mini_user = {
    steps_required: data?.me?.steps_required,
  };
  const { is_user_in_review } = getUserReviewStatus(mini_user);
  let all_uncancelled_requests =
    data?.me?.company?.diamond_requests?.filter(
      (x) => x.status !== 'CANCELLED'
    ) || [];
  const pending_request = all_uncancelled_requests?.filter(
    (x) => x.diamond === null
  );
  const available_in_system = all_uncancelled_requests.filter(
    (x) => x.diamond !== null
  );
  let month_format = 'MM';

  let pending_table_data = pending_request.map((request) => {
    return {
      id: request.id,
      request,
      gia_number: request.certNumber,
      seller: `-`,
      stock_id: request.supplier_stock_id,
      certNumber: (
        <CertWithIconV2
          certificate={{ lab: request.lab, certNumber: request.certNumber }}
          truncate_width={'90px'}
        />
      ),
      delivery_address: (
        <div style={{ ...theme.fonts.medium }}>
          <strong style={{ color: theme.palette.Neutrals700 }}>
            {request?.delivery_location?.name}
          </strong>{' '}
          {request?.delivery_location?.address1}{' '}
          {request?.delivery_location?.city}{' '}
          {request?.delivery_location?.postalCode}{' '}
        </div>
      ),
      payment_method: getPaymentTitleOnly(request?.payment_term_selected),
      requester: (
        <div style={{ textTransform: 'capitalize' }}>
          {' '}
          {request.requester.firstName} {request.requester.lastName}
          {(isMobile() && (
            <span className="card__content--created-at">
              {'  '}
              {formatDate(request.created_at, null, month_format)}
            </span>
          )) ||
            ''}
        </div>
      ),
      customer_order_number: request.customer_order_number || '',
      comment: request.comment || '',
      created_at: request.created_at,
      requested_date: formatDate(request.created_at, null, month_format),
      description: '-',
      diamond: request.diamond,
      diamond_price: request.diamond ? (
        request.diamond.offer.product_price
      ) : (
        <div className="card__content--diamond">-</div>
      ),
      diamond_discount: request.diamond ? (
        request.diamond.offer.product_discount
      ) : (
        <div className="card__content--diamond">-</div>
      ),
      diamond_amount:
        request.diamond && request.diamond.offer.product_price / 100,
      price: <div className="card__content--delivered">-</div>,
      diamond_size: request.diamond ? request.diamond.certificate.carats : 0,
      status: (
        <StatusPill
          name={request?.request_type === 'MEMO' ? request?.status : 'PENDING'}
          large
        />
      ),
      status_text: 'pending',
      action: (
        <Button
          is_default
          customPadding={isMobile() ? '6px 12px' : '6px'}
          className="diamond_request--cancel-request"
          onClick={() => {
            handleCancel(request.certNumber, request.id);
          }}
        >
          {/* <Icon
            color={'#1b237e'}
            name="delete"
            title={t('cancel_diamond_request')}
            desc={t('cancel_diamond_request')}
          /> */}
          {t('remove')}
        </Button>
      ),
      filterOptions: {
        status: 'pending',
        supplier: null,
        gia_number: request.certNumber,
        users: request.requester.id,
      },
    };
  });

  let in_house_table_data = available_in_system.map((request) => {
    const {
      diamond,
      diamond: { certificate },
    } = request;

    return {
      id: request.id,
      request,
      gia_number: request.certNumber,
      stock_id: request.supplier_stock_id,
      certNumber: `${certificate.lab != null ? certificate.lab : ''} ${
        request.certNumber
      }`,
      delivery_address: (
        <span>
          <strong style={{ color: theme.palette.Neutrals700 }}>
            {request?.delivery_location?.name}
          </strong>{' '}
          {request?.delivery_location?.address1}{' '}
          {request?.delivery_location?.city}{' '}
          {request?.delivery_location?.postalCode}
        </span>
      ),
      payment_method: getPaymentTitleOnly(request?.payment_term_selected),
      requester: (
        <div style={{ textTransform: 'capitalize' }}>
          {' '}
          {request.requester.firstName} {request.requester.lastName}
        </div>
      ),
      diamond: request.diamond,
      created_at: request.created_at,
      customer_order_number: request.customer_order_number || '',
      comment: request.comment || '',
      requested_date: formatDate(request.created_at, null, month_format),
      discount: Diamond.get_discount({ diamond }),
      diamond_discount: Diamond.get_diamond_discount({ diamond }),
      diamond_amount: diamond.offer.product_price / 100,
      delivered_amount: diamond.price,
      diamond_size: request.diamond ? request.diamond.certificate.carats : 0,
      price: (
        <ExchangeRate>
          {({ exchange_rate }) => {
            return (
              <div>
                {is_user_in_review ? (
                  <CustomTrans text="hidden" />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left',
                    }}
                  >
                    <div
                      style={{
                        color: '#35823C',
                        fontWeight: 500,
                        fontSize: 12,
                      }}
                    >
                      {Diamond.get_discount({ diamond })}
                    </div>

                    <span
                      style={{
                        color: '#2C2C2C',
                        fontWeight: 500,
                      }}
                    >
                      <Price
                        price={{
                          currency: 'USD',
                          amount: diamond.price,
                        }}
                      />
                    </span>
                    {exchange_rate.id !== 'USD' && (
                      <span
                        style={{
                          color: '#353434',
                          fontSize: '12px',
                        }}
                      >
                        <PriceExchanged
                          price={{ currency: 'USD', amount: diamond.price }}
                        />
                      </span>
                    )}
                  </div>
                )}
              </div>
            );
          }}
        </ExchangeRate>
      ),
      diamond_price: (
        <ExchangeRate>
          {({ exchange_rate }) => (
            <div>
              {is_user_in_review ? (
                <CustomTrans text="hidden" />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                  }}
                >
                  <div
                    style={{
                      color: '#35823C',
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    {Diamond.get_diamond_discount({ diamond })}
                  </div>
                  <span
                    style={{
                      color: '#2C2C2C',
                      fontWeight: 500,
                    }}
                  >
                    <Price
                      price={{
                        currency: 'USD',
                        amount: diamond.offer.product_price / 100,
                      }}
                    />
                  </span>
                  {exchange_rate.id !== 'USD' && (
                    <span
                      style={{
                        color: '#353434',
                        fontSize: '12px',
                      }}
                    >
                      <PriceExchanged
                        price={{
                          currency: 'USD',
                          amount: diamond.offer.product_price / 100,
                        }}
                      />
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </ExchangeRate>
      ),
      seller: (
        <div className="inline__seller">
          {!is_user_in_review && diamond.supplier.preferred ? (
            <img src={`/img/preferred_badge.svg`} alt="badge" />
          ) : (
            ''
          )}
          <HideOnReview>
            <span className="inline__seller--name">
              {diamond.supplier.name}
            </span>
          </HideOnReview>
        </div>
      ),
      // status: (
      //   <div
      //     className={`customer_order_status_label ${'Added'.replace(
      //       / /g,
      //       '-'
      //     )}`}
      //   >
      //     {/* {item.item_status.name} */}
      //     {'Added'}
      //   </div>
      // ),
      status: (
        <StatusPill
          name={
            request?.request_type === 'MEMO' ? request?.status : 'AVAILABLE'
          }
          large
        />
      ),
      filterOptions: {
        status: 'added',
        suppliers: diamond.supplier.id,
        gia_number: request.certNumber,
        users: request.requester.id,
      },
    };
  });
  let tableData = { pending_table_data, in_house_table_data };

  return {
    ...data,
    tableData,
    total_count: pending_table_data.length + in_house_table_data.length,
  };
};

export const diamondRequestTableHeaders = [
  // {
  //   id: 'certNumber',
  //   accessorKey: 'certNumber',
  //   cell: (info) => info.getValue(),
  //   header: () => <CustomTrans text={'identifier'} returnPlainText={true} />,
  // },
  {
    id: 'description',
    accessorKey: 'description',
    cell: (info) => info.getValue(),
    header: () => <CustomTrans text={'stone_info'} returnPlainText={true} />,
  },
  {
    id: 'requester',
    accessorKey: 'requester',
    cell: (info) => info.getValue(),
    header: () => <CustomTrans text={'user'} returnPlainText={true} />,
  },
  {
    id: 'requested_date',
    accessorKey: 'requested_date',
    cell: (info) => info.getValue(),
    header: () => (
      <CustomTrans text={'date_requested'} returnPlainText={true} />
    ),
  },
  {
    id: 'customer_order_number',
    accessorKey: 'customer_order_number',
    cell: (info) => info.getValue(),
    header: () => <CustomTrans text={'internal_no'} returnPlainText={true} />,
  },
  {
    id: 'comment',
    accessorKey: 'comment',
    cell: (info) => info.getValue(),
    header: () => <CustomTrans text={'note'} returnPlainText={true} />,
  },
  {
    id: 'delivery_address',
    accessorKey: 'delivery_address',
    cell: (info) => info.getValue(),
    header: () => <CustomTrans text={'address'} returnPlainText={true} />,
  },
  {
    id: 'payment_method',
    accessorKey: 'payment_method',
    cell: (info) => info.getValue(),
    header: () => (
      <CustomTrans text={'payment_method'} returnPlainText={true} />
    ),
  },
  // {
  //   id: 'diamond_price',
  //   accessorKey: 'diamond_price',
  //   cell: (info) => info.getValue(),
  //   header: () => <CustomTrans text={'diamond_price'} returnPlainText={true} />,
  // },
  {
    id: 'price',
    accessorKey: 'price',
    cell: (info) => info.getValue(),
    header: () => <CustomTrans text={'price'} returnPlainText={true} />,
  },
  // {
  //   id: 'delivered_price',
  //   accessorKey: 'price',
  //   cell: (info) => info.getValue(),
  //   header: () => (
  //     <CustomTrans text={'delivered_price'} returnPlainText={true} />
  //   ),
  // },
  {
    id: 'status',
    accessorKey: 'status',
    cell: (info) => info.getValue(),
    header: () => <CustomTrans text={'status'} returnPlainText={true} />,
  },
  {
    id: 'action',
    accessorKey: 'action',
    cell: (info) => info.getValue(),
    header: () => '',
  },
];

export const mapDiamondRequests = ({
  data: { get_company_diamond_requests = {} },
  is_user_in_review,
  refetch,
}) => {
  return get_company_diamond_requests?.items?.map((request) => {
    return {
      description:
        request?.diamond !== null ? (
          <div className="description">
            <div className="description_title">
              <DiamondTitle diamond={request.diamond} />
            </div>
            <div className="wrapper_cert_stock">
              <div className="cert_stock_parent">
                <CertWithIconV2
                  certificate={request?.diamond.certificate}
                  truncate_width={'150px'}
                  cert_wrapper={{
                    fontWeight: 400,
                    fontSize: '14px',
                    color: theme.palette.Neutrals500,
                  }}
                  hideIcon={true}
                  isOrderItem={false}
                  // supplierId={request?.diamond.supplier.id}
                />
                {request?.diamond?.supplierStockId && (
                  <>
                    <div className="cert_stock">
                      <div className="hide-on-lower"> • </div>
                      <CustomTrans
                        className="cert_stock_label"
                        text={'stock_id'}
                        returnPlainText={false}
                      />
                      {Diamond.get_stock_id(request?.diamond, {
                        returnPlainText: true,
                        truncate_width: '150px',
                      })}
                    </div>
                  </>
                )}
              </div>
              {(request?.certNumber || request?.diamond?.supplierStockId) && (
                <div className="copyWrapper">
                  <CopyInformation
                    items={[
                      {
                        label: 'copy_stock_id',
                        value: request?.diamond?.supplierStockId || null,
                      },
                      {
                        label: 'copy_certificate_number',
                        value: request?.certNumber,
                      },
                    ].filter(
                      (ele) => !(ele.value === null || ele.value?.length === 0)
                    )}
                    parentClassName={'tablerow_item'}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="request_cert_number_wrapper">
            <span className="request_cert_number">
              {request?.lab}: {request.certNumber}
            </span>
            <span className="request_cert_number">
              {request.supplier_stock_id}
            </span>
            {(request?.certNumber || request?.diamond?.supplierStockId) && (
              <div className="copyWrapper">
                <CopyInformation
                  items={[
                    {
                      label: 'copy_stock_id',
                      value: request?.diamond?.supplierStockId || null,
                    },
                    {
                      label: 'copy_certificate_number',
                      value: request?.certNumber,
                    },
                  ].filter(
                    (ele) => !(ele.value === null || ele.value?.length === 0)
                  )}
                  parentClassName={'tablerow_item'}
                />
              </div>
            )}
          </div>
        ),
      requester: (
        <div style={{ textTransform: 'capitalize' }}>
          {' '}
          {request.requester.firstName} {request.requester.lastName}
        </div>
      ),
      requested_date: dayjs(request.created_at).format('DD MMM YYYY'),
      customer_order_number: request?.customer_order_number || '-',
      comment: <WrapColumn>{request?.comment}</WrapColumn>,
      delivery_address: (
        <WrapColumn>
          <span>
            <strong style={{ color: theme.palette.Neutrals700 }}>
              {request?.delivery_location?.name}
            </strong>{' '}
            {request?.delivery_location?.address1}{' '}
            {request?.delivery_location?.city}{' '}
            {request?.delivery_location?.postalCode}
          </span>
        </WrapColumn>
      ),
      payment_method:
        getPaymentTitleOnly(request?.payment_term_selected) || '-',
      price:
        request?.diamond && !is_user_in_review ? (
          <RequestPrice diamond={request?.diamond} />
        ) : (
          '-'
        ),
      status: request?.status ? (
        <StatusPill
          name={request?.diamond ? 'AVAILABLE' : 'PENDING'}
          overrideStyles={{ width: 'fit-content', padding: '1px 4px' }}
        />
      ) : (
        '-'
      ),
      action: (
        <ActionRemoveButton
          id={request?.id}
          certNumber={request?.certNumber}
          disabled={request?.id === null || request?.certNumber === null}
          refetch={refetch}
          lab={request?.lab}
        />
      ),
    };
  });
};

const RequestPrice = ({ diamond }) => {
  const { is_aip } = useUserContext();
  return (
    <RequestPriceWrapper>
      {!is_aip && (
        <div className="diamond_price_section">
          <div className="diamond_price_section--title">
            <CustomTrans text={'diamond_price'} returnPlainText={true} />
          </div>
          <div className="wrapper">
            <div className="diamond_price_section--discount">
              {Diamond.get_diamond_discount({
                diamond: diamond,
              })}
            </div>
            <div className="diamond_price_section--actualPrice">
              <Price
                price={{
                  currency: 'USD',
                  amount: diamond?.offer.product_price / 100,
                }}
                className={'diamond_price_section--price'}
              />

              <PriceExchanged
                price={{
                  currency: 'USD',
                  amount: diamond?.offer.product_price / 100,
                }}
                className={'diamond_price_section--exchange'}
              />
            </div>
          </div>
        </div>
      )}
      {!is_aip && <div className="diamond_price_section--spacer"></div>}
      <div className="diamond_price_section">
        {!is_aip && (
          <div className="diamond_price_section--title">
            <CustomTrans text={'delivered_price'} returnPlainText={true} />
          </div>
        )}
        <div className="wrapper">
          <div className="diamond_price_section--discount">
            {Diamond.get_discount({ diamond })}
          </div>
          <div className="diamond_price_section--actualPrice">
            <Price
              price={{
                currency: 'USD',
                amount: diamond.price,
              }}
              className={'diamond_price_section--price'}
            />
            <PriceExchanged
              price={{
                currency: 'USD',
                amount: diamond.price,
              }}
              className={'diamond_price_section--exchange'}
            />
          </div>
        </div>
      </div>
    </RequestPriceWrapper>
  );
};

const ActionRemoveButton = ({ id, certNumber, disabled, refetch, lab }) => {
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const { status, users } = useDiamondRequestsFiltersStore();
  const [fnWrapper, context] = useErrorWrapper({
    showSucces: true,
    useToastNotification: true,
  });
  const [cancel_diamond_requests, { loading }] = useMutation(
    CUSTOMER_CANCEL_DIAMOND_REQUEST,
    {
      client: graphqlClient,
    }
  );
  const [deleteRow, setDeleteRow] = useState({
    gia_number: '',
    row_id: '',
  });
  const onRemoveClick = () => {
    setDeleteRow({ gia_number: certNumber, row_id: id });
    setShowCancelPopup(true);
  };
  const onCancelRequest = async () => {
    await fnWrapper(
      async () => {
        context.setBusy({
          type: 'CANCEL_REQUEST',
          status: true,
        });
        await cancel_diamond_requests({
          variables: { diamond_request_ids: [deleteRow.row_id] },
        });
        context.setBusy({
          type: '',
          status: false,
        });
      },
      null,
      {
        error: {
          messageResolver: (err) => {
            return (
              <CustomTrans
                style={{
                  ...theme.fonts.medium,
                  lineHeight: '14px',
                }}
                text={'failed_to_cancel_the_request'}
                returnPlainText={false}
              />
            );
          },
        },
        success: {
          messageResolver: (res) => {
            setShowCancelPopup(false);
            return (
              <CustomTrans
                style={{
                  ...theme.fonts.medium,
                  lineHeight: '14px',
                }}
                text={'successfully_cancelled_request'}
                data-automation-id="request-successfull"
                returnPlainText={false}
              />
            );
          },
        },
      }
    );
    refetch({ status, users });
  };

  return (
    <>
      <button
        className="action_btn"
        onClick={() => onRemoveClick()}
        disabled={disabled}
      >
        <Icon name="action-delete" size={16} color={theme.palette.red500} />
      </button>
      {showCancelPopup && (
        <CancelDiamondRequestPopup
          onClose={() => setShowCancelPopup(false)}
          gia_number={deleteRow.gia_number}
          onCancelRequest={() => onCancelRequest()}
          lab={lab}
          loading={loading}
        />
      )}
    </>
  );
};

export const isOnlyElement = (list, value) => {
  return list.length === 1 && list[0] === value;
};
