import { useEffect } from 'react';
import { useZendeskChatWidgetStore } from './useZendeskChatWidgetStore';
import { noop } from 'lodash';

export const useToggleZendeskHubspot = () => {
  const { setZendesk } = useZendeskChatWidgetStore();
  const toggleSupportWidget = () => {
    setZendesk(true);
  };
  return [toggleSupportWidget];
};

export const Zendesk = ({ handleOnInitialLoad = () => noop }) => {
  const ZENDESK_API_KEY = process.env.REACT_APP_ZENDESK_KEY;
  const isAdminPath = window.location.pathname.includes('/admin/');

  useEffect(() => {
    if (!isAdminPath) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_API_KEY}`;
      script.addEventListener('load', handleOnInitialLoad());

      document.body.appendChild(script);

      return () => {
        document.getElementById('ze-snippet')?.remove();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminPath]);
  return null;
};
