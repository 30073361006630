import styled, { css } from 'styled-components';
import theme from 'fabric/theme';

export const CopyWrapper = styled.div`
  display: grid;
  position: relative;
  margin-left: 4px;
  ${({ copyStyleOverride }) =>
    copyStyleOverride &&
    css`
      position: absolute;
      margin-top: -7px;
    `};
  .icon {
    position: relative;
    visibility: ${({ open }) => (open ? `visible` : `hidden`)};
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    border: none;
    outline: none;
    background: transparent;
    color: ${theme.palette.Neutrals700};
    &:hover,
    &:focus {
      color: ${theme.palette.violet700} !important;
      svg {
        color: ${theme.palette.violet700} !important;
      }
    }
  }
  .opened {
    color: ${theme.palette.violet700} !important;
    svg {
      color: ${theme.palette.violet700} !important;
    }
  }

  .info {
    position: absolute;
    top: 25px;
    z-index: 1;
    display: none;
    border: 1px solid ${theme.palette.Neutrals200};
    border-radius: ${theme.spacing.s3};
    padding: ${theme.spacing.s2};
    background-color: ${theme.palette.white};
    div {
      padding: ${theme.spacing.s3};
      width: 320px;
      text-align: left;
      cursor: pointer;
      h6 {
        margin: 0;
        text-transform: unset;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: ${theme.palette.themePrimary};
      }
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
      }
      &:hover {
        background-color: ${theme.palette.violet50};
        border-radius: 6px;
        h6 {
          color: ${theme.palette.violet700};
        }
      }
    }
  }
  .open {
    display: grid;
  }
`;
