import React, { useState } from 'react';
import { Tooltip } from '@nivoda/ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { tFn } from 'utils';
import theme from 'fabric/theme';
import { Icon } from 'fabric/resources/icon';

const CopyToClipboardWrapper = styled.div`
  display: flex;
  .copy_icon {
    cursor: pointer;
    color: ${({ iconColor }) => iconColor || '#a79595'};
    &:hover {
      color: ${({ hoverColor }) => hoverColor || '#a79595'};
    }
  }
`;

export default function CopyToClipboard({
  text,
  copyContent,
  iconName = 'copy',
  iconHeight,
  iconWidth,
  iconSize,
  iconColor,
  hoverColor,
  toolTipStyles,
  placement,
  noTranslate = false,
  showGreenTick = false,
  offset,
}) {
  const { t } = useTranslation();
  let method = noTranslate ? null : t;

  const [isCopied, setIsCopied] = useState(false);
  return (
    <CopyToClipboardWrapper
      className="copy__to__clipboard"
      data-automation-id="copy__to__clipboard"
      iconColor={iconColor}
      hoverColor={hoverColor}
    >
      <Tooltip
        content={
          <div
            style={{ width: 'max-content', fontSize: '12px', ...toolTipStyles }}
          >
            {isCopied
              ? `${tFn(method, 'copied', 'Copied')} ${text}`
              : `${tFn(method, 'click_to_copy', 'Click to copy')} ${text}`}
          </div>
        }
        placement={placement ? placement : 'top'}
        offset={offset ? offset : 5}
      >
        <Icon
          name={isCopied && showGreenTick ? 'check_2' : iconName}
          className={isCopied && showGreenTick ? 'check' : 'copy_icon'}
          color={
            isCopied && showGreenTick ? theme.palette.greenNew600 : iconColor
          }
          width={iconWidth}
          height={iconHeight}
          size={iconSize}
          onClick={async (e) => {
            e.stopPropagation();
            if (copyContent) {
              await navigator.clipboard.writeText(copyContent);
            } else {
              await navigator.clipboard.writeText(text);
            }
            setIsCopied(true);
            setTimeout(() => {
              setIsCopied(false);
            }, 2000);
          }}
        />
      </Tooltip>
    </CopyToClipboardWrapper>
  );
}
