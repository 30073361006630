import styled, { css } from 'styled-components';

import theme from 'fabric/theme';

export const RequestsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s6};
  height: calc(100vh - 71px);
  padding: ${theme.spacing.s6};

  .container-1 {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.s4};
  }
`;

export const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s3};
  border-bottom: 1px solid ${theme.palette.Neutrals200};

  .category-tab {
    cursor: pointer;
    box-sizing: border-box;
    ${theme.fonts.mediumPlus};
    line-height: 20px;
    height: ${theme.spacing.s12};
    padding: 14px ${theme.spacing.s2};
    color: ${theme.palette.Neutrals700};

    &.active {
      color: ${theme.palette.themeSecondary};
      border-bottom: 2px solid ${theme.palette.themeSecondary};
    }

    &:hover {
      color: ${theme.palette.themeSecondary};
    }
  }
`;

export const MeleeReqStatusPillWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s2};

  .req-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${({ iconColor }) => iconColor};
  }

  .req-status {
    ${theme.fonts.smallPlus};
    font-size: 13px;
    line-height: 14px;
    color: ${({ textColor }) => textColor};
    ${({ badgeColor }) =>
      badgeColor &&
      css`
        display: flex;
        align-items: center;
        border-radius: 9999px;
        gap: ${theme.spacing.s1};
        background: ${badgeColor};
        padding: 5px ${theme.spacing.s2};
      `}
  }

  .req-info-pill {
    ${theme.fonts.smallPlus};
    font-size: 13px;
    line-height: 13px;
    border-radius: 9999px;
    padding: 5px ${theme.spacing.s2};
    color: ${theme.palette.Neutrals700};
    background: ${theme.palette.offWhite4};
  }
`;

export const RequestWrapper = styled.div`
  #requests__table {
    padding: 0;

    .no-diamonds-match {
      margin-bottom: unset;
      height: calc(100vh - 300px);
    }

    .table__header {
      background: ${theme.palette.white};
      border: 1px solid ${theme.palette.gray200};
      border-top: none;
      z-index: 2;
      .table__row {
        padding: 0;
        border-top: unset;
        border-bottom: unset;

        & .table__col {
          word-wrap: break-word;
          ${theme.fonts.smallBold};
          padding: ${theme.spacing.s3};
          line-height: ${theme.spacing.s4};
          color: ${theme.palette.Neutrals700};
        }
      }
    }

    #table_wrapper {
      box-shadow: none;
      border-bottom: 1px solid ${theme.palette.gray200};
      border-bottom-right-radius: ${theme.spacing.s1};
      border-bottom-left-radius: ${theme.spacing.s1};
    }

    #table__body {
      max-height: calc(100vh - 300px);
    }

    .table__body_row {
      border-left: 1px solid ${theme.palette.gray200};
      border-right: 1px solid ${theme.palette.gray200};
      border-bottom: 1px solid ${theme.palette.gray100};

      :last-child {
        border-bottom: none;
      }

      :hover {
        .table__row {
          box-shadow: none;
        }
      }

      .table__row {
        border-bottom: none;
        padding: 0;

        .table__col {
          height: 72px;
          word-wrap: break-word;
          ${theme.fonts.smallPlus};
          line-height: ${theme.spacing.s4};
          color: ${theme.palette.Neutrals700};
          padding: ${theme.spacing.s5} ${theme.spacing.s3};

          .reqNum,
          .melee-info {
            display: flex;
            gap: ${theme.spacing.s1};
            ${theme.fonts.smallBold};
            line-height: ${theme.spacing.s4};
          }

          .reqNum {
            & svg > path {
              stroke-width: 1px;
            }
          }

          .comments {
            width: 90%;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .no-comments {
            color: ${theme.palette.neutral400};
            font-style: italic;
          }
        }
      }
    }

    #melee-req-table-gray-row {
      .table__col,
      .reqNum,
      .melee-info,
      .comments {
        color: ${theme.palette.neutral400};
      }
    }

    .skeleton:empty:after {
      width: 100px;
    }
  }

  .filter__section_wrapper {
    padding: ${theme.spacing.s3};
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.gray200};
    border-top-left-radius: ${theme.spacing.s1};
    border-top-right-radius: ${theme.spacing.s1};

    .filter__section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--left {
        display: flex;
        align-items: center;
        gap: ${theme.spacing.s2};

        .filter_by__main-btn {
          ${theme.fonts.smallBolder};
          gap: 6px;
          display: flex;
          margin: unset;
          align-items: center;
          height: ${theme.spacing.s8};
          color: ${theme.palette.gray800};
          line-height: ${theme.spacing.s4};
          border-radius: ${theme.spacing.s1};
          background-color: ${theme.palette.white};
          border: 1px solid ${theme.palette.gray300};
          padding: 6px ${theme.spacing.s2} !important;

          :hover {
            box-shadow: none;
            border: 1px solid ${theme.palette.gray400};
          }
        }

        .filter_by__main-btn.has_filters {
          background: ${theme.palette.gray50};
        }
      }

      &--right {
        display: flex;
        align-items: center;
        gap: ${theme.spacing.s1};

        &-input {
          display: flex;
          align-items: center;
          gap: ${theme.spacing.s1};
          border-radius: ${theme.spacing.s1};
          border: 1px solid ${theme.palette.gray300};
          padding: ${theme.spacing.s1} ${theme.spacing.s2};

          &:hover {
            box-shadow: none;
            border: 1px solid ${theme.palette.gray400};
          }

          &-search {
            width: 180px;
            padding-left: unset;
            background: ${theme.palette.white};

            & .searchbar_input_text {
              width: 168px;
              height: 22px;
              border: unset;
              padding: unset;
              margin: unset;
              box-shadow: none;
              ${theme.fonts.mediumPlus};
              line-height: 20px;
            }

            & ._clear_icon {
              top: 0;
              right: 0;
              font-size: 15px;
            }
          }
        }
      }
    }
  }
`;

export const FilterContentWrapper = styled.div`
  .label_style {
    ${theme.fonts.mediumBold};
    color: ${theme.palette.gray800};
  }
  .filter__ {
    &header {
      ${theme.fonts.xLargeBold};
      color: ${theme.palette.gray800};
      padding: ${theme.spacing.s4} 20px;
      border-bottom: 1px solid ${theme.palette.gray200};
    }
    &item {
      &--actions {
        padding: 10px !important;
      }

      & .react-datepicker-popper {
        transform: translate3d(11px, 64px, 0px) !important;
      }
    }
  }
`;
