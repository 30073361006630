import { Popup } from '@nivoda/ui';
import { CancelDiamondRequestPopupWrapper } from './style';
import { Icon } from 'fabric/resources/icon';
import theme from 'fabric/theme';
import { CustomTrans } from 'helpers';
import './style.css';
import { Trans } from 'react-i18next';
import { Button } from 'fabric/components';

export const CancelDiamondRequestPopup = ({
  onClose,
  gia_number,
  lab,
  onCancelRequest,
  loading,
}) => {
  return (
    <Popup
      hide_title={true}
      className="diamond_request_cancel_popup"
      onClose={onClose}
    >
      <CancelDiamondRequestPopupWrapper>
        <div className="close_btn_section" onClick={onClose}>
          <Icon name="close-2" size={16} color={theme.palette.Neutrals500} />
        </div>
        <div className="cancel_popup_title">
          <CustomTrans text={'cancel_diamond_request'} returnPlainText={true} />
        </div>
        <div className="cancel_popup_content">
          <div className="cancel_popup_content--title">
            <Trans
              i18nKey={'about_cancel_request_lab'}
              values={{
                lab: lab,
                certNumber: gia_number,
              }}
              components={{ bold: <strong /> }}
            />
          </div>
          {/* <div className="cancel_popup_content--title">
            <CustomTrans
              text={'do_you_wish_to_continue'}
              returnPlainText={true}
            />
          </div> */}
        </div>
        <div className="cancel_popup_footer">
          <Button
            className="no_btn"
            onClick={() => onClose()}
            disabled={loading}
          >
            <CustomTrans text={'no'} returnPlainText={true} />
            {loading && (
              <Icon name="loader" size={14} color={theme.palette.white} />
            )}
          </Button>
          <Button
            className="yes_btn"
            onClick={() => onCancelRequest()}
            disabled={loading}
          >
            <CustomTrans text={'yes'} returnPlainText={true} />
            {loading && (
              <Icon name="loader" size={14} color={theme.palette.white} />
            )}
          </Button>
        </div>
      </CancelDiamondRequestPopupWrapper>
    </Popup>
  );
};
