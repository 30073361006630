import React from 'react';

import theme from 'fabric/theme';
import { Icon } from 'fabric/resources/icon';
import { MeleeReqCxStatuses } from '../helpers';
import { MeleeReqStatusPillWrapper } from './styles';

const MeleeReqStatusPill = ({
  info = null,
  status = MeleeReqCxStatuses?.REQUESTED?.value,
}) => {
  const {
    label,
    iconColor = theme.palette.Neutrals400,
    textColor = theme.palette.Neutrals700,
    badgeColor = '',
    iconName = '',
  } = Object.values(MeleeReqCxStatuses).filter(
    (item) => item?.value === status
  )?.[0] ?? {};

  return (
    <MeleeReqStatusPillWrapper
      iconColor={iconColor}
      textColor={textColor}
      badgeColor={badgeColor}
    >
      {!badgeColor ? <span className="req-indicator" /> : null}
      <span className="req-status">
        {iconName ? <Icon name={iconName} size={14} color={iconName} /> : null}
        {label}
      </span>
      {info ? <span className="req-info-pill">{info}</span> : null}
    </MeleeReqStatusPillWrapper>
  );
};

export default MeleeReqStatusPill;
