import styled from 'styled-components';
import { theme } from 'fabric/theme';

export const RequestStoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${theme.spacing.s6};

  .stone_information {
    ${theme.fonts.mediumBold}
    color: ${theme.palette.themePrimary};
  }
  .request_cert_number {
    ${theme.fonts.medium}
    color:${theme.palette.Neutrals500};
  }
  .request_cert_number_wrapper {
    min-width: 250px;
    width: 250px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-start;
    gap: 10px;
  }
  .description {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    min-width: 250px;
    width: 250px;
    position: relative !important;
    .cert_wrapper {
      padding-right: unset !important;
    }
    .wrapper_cert_stock {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
    }
    .cert_stock_parent {
      text-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      & .hide-on-lower {
        display: none;
      }
    }
    .cert_stock {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      .cert_stock_label {
        ${theme.fonts.medium}
        color: ${theme.palette.Neutrals500};
      }
    }
    .customer_comment {
      ${theme.fonts.medium}
      color:${theme.palette.Neutrals500};
      word-wrap: break-word;
      word-break: break-all;
      white-space: break-spaces;
      text-wrap: wrap !important;
    }
    .description_title {
      font-family: 'Inter' !important;
      font-size: 14px !important;
      line-height: 14px !important;
      font-weight: 600;
      color: ${theme.palette.themePrimary} !important;
      text-wrap: wrap !important;
    }

    .cert_stock_parent {
      .copyWrapper {
        position: absolute;
        right: 0;
        background: #fff;
        padding: 0 8px;
      }
    }
  }
  .empty_reset_filters {
    width: fit-content;
    max-width: 200px;
    border-radius: 8px;
    padding: 12px !important;
    border: 1px solid ${theme.palette.Neutrals300};
    background: ${theme.palette.white};
    line-height: 16px;
    ${theme.fonts.mediumPlus}
    color:${theme.palette.Neutrals700};
    .backBtn-icon {
      transform: rotate(180deg);
    }
    :hover {
      border: 1px solid ${theme.palette.blueStoke};
      background: ${theme.palette.white};
      color: ${theme.palette.themeSecondary};
      box-shadow: none;
      svg {
        color: ${theme.palette.themeSecondary};
      }
    }
  }
  .filters__container {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    row-gap: 6px;
    .new_request {
      border-radius: 5px;
      padding: 10px !important;
      ${theme.fonts.smallBolder}
      :hover {
        box-shadow: none;
        background: ${theme.palette.brandBlue4};
      }
    }
    &--left {
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing.s2};
      row-gap: 6px;
      align-items: center;
      width: 78%;
    }
    // @media only screen and (max-width: 768px) {
    //   &--left {
    //     flex-wrap: wrap;
    //     row-gap: 6px;
    //   }
    // }
    margin-bottom: 16px;
    .filters__search {
      width: 250px !important;
      border: 1px solid #e4e4e7;
      border-radius: ${theme.spacing.s2};
      background: ${theme.palette.offWhite4};
      padding: 5px 8px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .custom_icon {
        position: unset;
      }
      input {
        height: unset;
        background: transparent;
        border: none;
        padding: 0 6px;
      }
      input::placeholder {
        font-size: 14px;
      }
      /* ._clear_icon {
        position: unset;
      } */
    }
    &--clear {
      min-width: auto;
      border-radius: ${theme.spacing.s2};
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      padding: 6px 8px;
      gap: 7px;
      margin: 0 !important;
      color: ${theme.palette.Neutrals700};
      font-weight: 500;
      &:hover {
        color: ${theme.palette.violet700} !important;
        svg {
          color: ${theme.palette.violet700};
        }
      }
      &:focus {
        color: ${theme.palette.violet700};
        background-color: ${theme.palette.violet100};
        svg {
          color: ${theme.palette.violet700};
        }
      }
    }
  }
  .tsw_table_wrp {
    overflow-x: overlay;
    height: calc(100vh - 295px);
  }
  .ts_action_cell {
    width: 100px !important;
    .ts_action_sub_cell {
      width: 100px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      .action_btn {
        background: transparent;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        &:hover {
          background: ${theme.palette.Neutrals100};
        }
      }
    }
  }
  #ts_table_header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: ${theme.palette.white} !important;
  }
  #ts_table_body td {
    text-wrap: wrap !important;
  }

  #ts_table_body {
    tr:hover {
      .copyWrapper {
        background: #fafaf9;
      }
      .icon {
        display: flex;
        visibility: visible;
      }
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1240px) {
    #ts_table_body {
      tr {
        .copyWrapper {
          position: absolute;
          right: 0;
          background: #fff;
          padding: 0 8px;
        }
      }
      tr:hover {
        .copyWrapper {
          background: #fafaf9;
        }
      }
    }
    .description {
      width: 100%;
      min-width: 200px;
      position: relative;
    }
    .description > .cert_stock_parent {
      text-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      & .hide-on-lower {
        display: none;
      }
    }
  }
`;

export const WrapColumn = styled.div`
  text-align: left;
  word-wrap: break-word;
  width: 200px;
`;

export const RequestPriceWrapper = styled.div`
  .diamond_price_section {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    .wrapper {
      text-align: right;
    }

    &--spacer {
      height: 10px !important;
    }
    &--title {
      ${theme.fonts.mediumBold}
      color:${theme.palette.themePrimary};
      line-height: 14px;
    }
    &--discount {
      ${theme.fonts.smallPlus}
      color: ${theme.palette.themeSecondary};
    }
    &--actualPrice {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    &--price {
      ${theme.fonts.mediumBold}
      color:${theme.palette.themePrimary};
      line-height: 14px;
    }
    &--exchange {
      ${theme.fonts.small}
      color:${theme.palette.Neutrals400};
      line-height: 12px;
    }
  }
`;
