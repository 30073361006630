import { gql } from '@apollo/client';
import {
  AdminFullOrderItemFragment,
  AdminOrderItemCShipmentFragment,
  AdminOrderItemSbsCShipmentFragment,
} from './OrderItemFragment';

export let ShipmentFragment = gql`
  fragment ShipmentFragment on Shipment {
    id
    label
    commercial_invoice
    commercial_invoice_lg
    customs_invoice
    carrier
    service_type
    parent_shipment {
      id
    }
    box_type
    status
    scanned_items
    shipment_status
    shipment_comment
    supplier_shipment
    shipment_created
    shipment_in_transit
    shipment_delivered
    label_downloaded
    break_bulk_excel
    india_customs_sheet
    package_slip
    bulk_break
    intl

    tracking_code
    address_printed
    envelope_number
    insurance_label
    label_page_one
    shipping_bill
    shipping_bill_date
    declaration_certificate

    items {
      id
      shipping_reference_number
    }
    child_shipments {
      id
      status
      envelope_number
      package_slip
    }

    origin {
      id
      name
    }
    destination {
      id
      name
    }
  }
`;

export const OfficeOrderItemFragment = gql`
  fragment OfficeOrderItemFragment on OrderItem {
    id
    order_number
    base_sell_price
    nivoda_markup
    status
    destination {
      country
      address1
      location_code
    }
    origin {
      country
      address1
      location_code
    }
    buyer {
      company {
        company_settings {
          company_code
          customer_order_no_on_bc
          CompanyId
        }
      }
    }
    FbnType
    Product {
      ... on Melee {
        IsParentMeleeCollection
      }
      ... on MeleeCollection {
        IsParentMeleeCollection
      }
      __typename
    }
    ProductType
    shipping_reference_number
    latest_sbs_child_shipment {
      id
      status
      envelope_number
      parent_shipment {
        origin {
          country
        }
      }
      envelope_barcode {
        barcode_text
      }
    }
    Qc {
      id
      status
    }
    QcGemstone {
      id
      status
    }
  }
`;

export const OfficeToShipmentFragment = gql`
  fragment OfficeToShipment on Shipment {
    id
    box_type
    commercial_invoice
    commercial_invoice_lg
    package_slip
    label
    label_downloaded
    tracking_code
    items {
      ...AdminFullOrderItem
    }
    destination {
      id
      address1
      address2
      postalCode
      office_code
      suburb
      city
      state
      name
      country
      company {
        id
        name
      }
      owner {
        id
        company {
          id
          name
        }
      }
    }
  }
  ${AdminFullOrderItemFragment}
`;

export const ChildShipmentFragment = gql`
  fragment ChildShipment on Shipment {
    id
    carrier
    status
    shipment_status
    shipment_created
    box_type
    commercial_invoice
    commercial_invoice_lg
    package_slip
    label
    bulk_break
    label_downloaded
    break_bulk_excel
    india_customs_sheet
    tracking_code
    scanned_items
    destination {
      id
      address1
      postalCode
      city
      state
      name
      country
      company {
        id
        name
      }
      company_name
    }
    origin {
      id
      address1
      postalCode
      city
      state
      name
      country
      company {
        id
        name
      }
      company_name
    }
    envelope_number
    items {
      ...AdminOrderItemCShipmentFragment
    }
  }
  ${AdminOrderItemCShipmentFragment}
`;

export const SbsChildShipmentFragment = gql`
  fragment ChildShipment on Shipment {
    id
    carrier
    status
    shipment_status
    shipment_created
    box_type
    envelope_barcode {
      barcode_text
    }
    child_shipment_items {
      id
      item {
        hubItemCollection {
          id
          nivodaHub {
            id
            name
            city
            country
          }
        }
        id
        ...AdminOrderItemSbsCShipmentFragment
      }
      child_shipment {
        id
      }
      cshipment {
        id
      }
      envelope_number
      sub_envelope_number
      sequence_number
      scanned_in_shipment
      envelope_barcode {
        barcode_text
      }
    }
    commercial_invoice
    commercial_invoice_lg
    package_slip
    label
    bulk_break
    label_downloaded
    break_bulk_excel
    india_customs_sheet
    tracking_code
    destination {
      id
      address1
      postalCode
      city
      state
      name
      country
      company {
        id
        name
      }
      company_name
    }
    origin {
      id
      address1
      postalCode
      city
      state
      name
      country
      company {
        id
        name
      }
      company_name
    }
    envelope_number
  }
  ${AdminOrderItemSbsCShipmentFragment}
`;
