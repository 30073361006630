import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { isActiveSet } from '../../helpers';
import { GraphqlMutation } from 'graphql/factory';
import { gql } from '@apollo/client';

let def_query = {
  status: [],
  suppliers: [],
  users: [],
  text: '',
};
export function filterBy(items = [], query) {
  let has_query = isActiveSet(query, def_query);

  if (!has_query) {
    return items;
  }
  let query_text_array =
    query?.text === ''
      ? []
      : query?.text
          ?.split(',') // search texts are separated out by comma
          .map((t) => t.trim()) // trim spaces
          .filter(Boolean); // filter only non empty strings
  let text_result = flatten(
    query_text_array.length > 0
      ? query_text_array.map((query_text) =>
          items.filter((i) =>
            i?.gia_number?.toLowerCase()?.includes(query_text?.toLowerCase())
          )
        )
      : []
  );

  let status_result = flatten(
    query.status.map((status_item) =>
      items.filter(
        (item) => item.filterOptions.status === status_item.toLowerCase()
      )
    )
  );
  let supplier_result = flatten(
    query.suppliers.map((supplier) =>
      items.filter((item) => item.filterOptions.suppliers === supplier.value)
    )
  );

  let user_result = flatten(
    query.users.map((user) =>
      items.filter((item) => item.filterOptions.users === user.value)
    )
  );

  return uniq([
    ...text_result,
    ...status_result,
    ...supplier_result,
    ...user_result,
  ]);
}

export const CustomerCancelDiamondRequest = GraphqlMutation({
  query: gql`
    mutation ($diamond_request_ids: [ID!]!) {
      cancel_diamond_requests(diamond_request_ids: $diamond_request_ids) {
        id
        status
      }
    }
  `,
});

export const CUSTOMER_CANCEL_DIAMOND_REQUEST = gql`
  mutation ($diamond_request_ids: [ID!]!) {
    cancel_diamond_requests(diamond_request_ids: $diamond_request_ids) {
      id
      status
    }
  }
`;
