import { create } from 'zustand';

export const useDiamondRequestsFiltersStore = create((set, get) => ({
  status: null,
  setStatus: (value) => {
    set({ status: value });
  },
  users: [],
  setUsers: (user) => {
    let temp = [...user];
    set({ users: temp });
  },
}));
