// import theme from 'fabric/theme';

// import { Button } from 'fabric/components';
import { compareDateFunction, compareFunction } from 'utils';
import theme from 'fabric/theme';

export const statuses = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  INTERNAL_FOLLOW_UP: 'INTERNAL_FOLLOW_UP',
  QUOTE_READY: 'QUOTE_READY',
  PENDING_CONFIRMATION: 'PENDING_CONFIRMATION',
  WITHDRAWN: 'WITHDRAWN',
  CX_FOLLOW_UP: 'CX_FOLLOW_UP',
  ACCEPTED: 'ACCEPTED',
  ORDER_PLACED: 'ORDER_PLACED',
  EXPIRED_QUOTE: 'EXPIRED_QUOTE',
  REQUOTE_REQUESTED: 'REQUOTE_REQUESTED',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  QUOTE_SENT: 'QUOTE_SENT',
  QUOTED: 'QUOTED',
  ARCHIVED: 'ARCHIVED',
  DONE: 'DONE',
};

export const INTERNAL_STATUSES = [
  { label: 'Open', value: 'OPEN' },
  {
    label: 'In progress',
    value: 'IN_PROGRESS',
  },
  { label: 'Internal Follow Up', value: 'INTERNAL_FOLLOW_UP' },
  { label: 'Quote Sent', value: 'QUOTE_SENT' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Accepted', value: 'ACCEPTED' },
  { label: 'Order placed', value: 'ORDER_PLACED' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'Expired', value: 'EXPIRED' },
];

export const INACTIVE_STATUS = [
  statuses.WITHDRAWN,
  statuses?.EXPIRED_QUOTE,
  statuses?.REQUOTE_REQUESTED,
];

export const SPR_STATUS = {
  OPEN: {
    id: 'open',
    label: 'OPEN',
    value: statuses?.OPEN,
    color: '#1D4ED8',
    background: theme.palette.blue100,
    options: [
      statuses.OPEN,
      statuses.IN_PROGRESS,
      statuses.INTERNAL_FOLLOW_UP,
      statuses.QUOTE_READY,
      statuses.PENDING_CONFIRMATION,
      statuses.WITHDRAWN,
      statuses.REJECTED,
      statuses.ACCEPTED,
      statuses.CX_FOLLOW_UP,
      statuses.REQUOTE_REQUESTED,
    ],
  },
  INTERNAL_FOLLOW_UP: {
    id: 'internal_follow_up',
    label: 'INTERNAL FOLLOW UP',
    color: '#D97706',
    background: theme.palette.yellow100,
    options: [
      statuses.INTERNAL_FOLLOW_UP,
      statuses.OPEN,
      statuses.IN_PROGRESS,
      statuses.QUOTE_READY,
      statuses.PENDING_CONFIRMATION,
      statuses.WITHDRAWN,
      statuses.REJECTED,
      statuses.ACCEPTED,
      statuses.CX_FOLLOW_UP,
      statuses.REQUOTE_REQUESTED,
    ],
  },
  PENDING_CONFIRMATION: {
    id: 'pending_confirmation',
    label: 'PENDING CONFIRMATION',
    color: '#D97706',
    background: theme.palette.yellow100,
    options: [
      statuses.PENDING_CONFIRMATION,
      statuses.OPEN,
      statuses.IN_PROGRESS,
      statuses.INTERNAL_FOLLOW_UP,
      statuses.QUOTE_READY,
      statuses.WITHDRAWN,
      statuses.REJECTED,
      statuses.ACCEPTED,
      statuses.CX_FOLLOW_UP,
      statuses.REQUOTE_REQUESTED,
    ],
  },
  QUOTE_READY: {
    id: 'quote_ready',
    label: 'QUOTE READY',
    color: '#1D4ED8',
    value: 'QUOTE_READY',
    background: theme.palette.blue100,
    options: [
      statuses.QUOTE_READY,
      statuses.OPEN,
      statuses.IN_PROGRESS,
      statuses.INTERNAL_FOLLOW_UP,
      statuses.PENDING_CONFIRMATION,
      statuses.WITHDRAWN,
      statuses.REJECTED,
      statuses.ACCEPTED,
      statuses.CX_FOLLOW_UP,
      statuses.REQUOTE_REQUESTED,
    ],
  },
  REQUOTE_REQUESTED: {
    id: 'requote_requested',
    label: 'RE-QUOTE REQUESTED',
    color: '#1D4ED8',
    background: theme.palette.blue100,
    options: [
      statuses.REQUOTE_REQUESTED,
      statuses.OPEN,
      statuses.IN_PROGRESS,
      statuses.PENDING_CONFIRMATION,
      statuses.INTERNAL_FOLLOW_UP,
      statuses.QUOTE_READY,
      statuses.WITHDRAWN,
      statuses.REJECTED,
      statuses.ACCEPTED,
      statuses.CX_FOLLOW_UP,
    ],
  },
  ACCEPTED: {
    id: 'accepted',
    label: 'ACCEPTED',
    color: '#15803D',
    background: '#DCFCE7',
    options: [
      statuses.ACCEPTED,
      statuses.OPEN,
      statuses.IN_PROGRESS,
      statuses.PENDING_CONFIRMATION,
      statuses.INTERNAL_FOLLOW_UP,
      statuses.QUOTE_READY,
      statuses.WITHDRAWN,
      statuses.REJECTED,
      statuses.ACCEPTED,
      statuses.CX_FOLLOW_UP,
    ],
  },
  ORDER_PLACED: {
    id: 'order_placed',
    label: 'ORDER PLACED',
    color: '#15803D',
    background: '#DCFCE7',
    options: [statuses.ORDER_PLACED],
  },
  REJECTED: {
    id: 'rejected',
    label: 'REJECTED',
    color: theme.palette.red700,
    background: theme.palette.red100,
    options: [statuses.REJECTED],
  },
  WITHDRAWN: {
    id: 'withdrawn',
    label: 'WITHDRAWN',
    color: theme.palette.red700,
    background: theme.palette.red100,
    options: [
      statuses.WITHDRAWN,
      statuses.OPEN,
      statuses.IN_PROGRESS,
      statuses.PENDING_CONFIRMATION,
      statuses.INTERNAL_FOLLOW_UP,
      statuses.QUOTE_READY,
      statuses.REJECTED,
      statuses.ACCEPTED,
      statuses.CX_FOLLOW_UP,
      statuses.REQUOTE_REQUESTED,
    ],
  },
  EXPIRED_QUOTE: {
    id: 'expired',
    label: 'EXPIRED',
    color: theme.palette.red700,
    background: theme.palette.red100,
    options: [
      statuses.EXPIRED_QUOTE,
      statuses.OPEN,
      statuses.IN_PROGRESS,
      statuses.INTERNAL_FOLLOW_UP,
      statuses.QUOTE_READY,
      statuses.PENDING_CONFIRMATION,
    ],
  },
  IN_PROGRESS: {
    id: 'in_progress',
    label: 'IN PROGRESS',
    color: '#D97706',
    background: theme.palette.yellow100,
    options: [
      statuses.IN_PROGRESS,
      statuses.OPEN,
      statuses.INTERNAL_FOLLOW_UP,
      statuses.QUOTE_READY,
      statuses.PENDING_CONFIRMATION,
      statuses.WITHDRAWN,
      statuses.REJECTED,
      statuses.ACCEPTED,
      statuses.CX_FOLLOW_UP,
      statuses.REQUOTE_REQUESTED,
    ],
  },
  CX_FOLLOW_UP: {
    id: 'cx_follow_up',
    label: 'CX FOLLOW',
    color: '#1D4ED8',
    background: theme.palette.blue100,
    options: [
      statuses.CX_FOLLOW_UP,
      statuses.OPEN,
      statuses.IN_PROGRESS,
      statuses.INTERNAL_FOLLOW_UP,
      statuses.QUOTE_READY,
      statuses.PENDING_CONFIRMATION,
      statuses.WITHDRAWN,
      statuses.REJECTED,
      statuses.ACCEPTED,
      statuses.REQUOTE_REQUESTED,
    ],
  },
};

export const sprStatusOpt = (
  status,
  isQuoteReadyDisabled,
  isCaratsAndPiecesAvailable
) => {
  return SPR_STATUS[status]?.options.map((opt) => {
    let labelSplit = opt.split('_');
    return {
      label: `${labelSplit[0]} ${labelSplit[1] || ''} ${labelSplit[2] || ''}`,
      value: opt,
      disabled: !!(
        opt === 'QUOTE_READY' &&
        (isQuoteReadyDisabled || isCaratsAndPiecesAvailable)
      ),
    };
  });
};
// export const SR_COMPARE_TABLE_HEADERS = [
//   {
//     id: 'property',
//     colLabel: 'Property',
//     name: 'Property',
//     selector: 'property',
//   },
//   {
//     id: 'original_value',
//     colLabel: 'Original value',
//     name: 'Original value',
//     selector: 'original_value',
//   },
//   {
//     id: 'quoted_value',
//     colLabel: 'Quoted value',
//     name: 'New quoted value',
//     selector: 'quoted_value',
//   },
// ];

export const SPR_TABLE_HEADERS = [
  {
    id: 'order_request_number',
    colLabel: 'SR number',
    name: 'SR number',
    selector: 'order_request_number',
    sortable: true,
    sortFunction: (a, b) =>
      compareFunction(
        a.item?.order_request_number,
        b.item?.order_request_number
      ),
  },
  {
    id: 'request_date',
    colLabel: 'Request date',
    name: 'Request date',
    selector: 'request_date',
    sortable: true,
    sortFunction: (a, b) =>
      compareDateFunction(a.item?.created_at, b.item?.created_at, true),
  },
  {
    id: 'source',
    colLabel: 'Source',
    name: 'Source',
    selector: 'source',
    sortable: false,
  },
  {
    id: 'company',
    colLabel: 'Company',
    name: 'Company',
    selector: 'company',
    sortable: false,
  },
  {
    id: 'type',
    colLabel: 'Type',
    name: 'Type',
    selector: 'type',
    sortable: false,
  },
  {
    id: 'stone_info',
    colLabel: 'Stone info',
    name: 'Melee Details',
    selector: 'stone_info',
    sortable: false,
  },
  {
    id: 'size',
    colLabel: 'Size',
    name: 'Size & Qty',
    selector: 'size',
    sortable: false,
  },
  {
    id: 'order_number',
    colLabel: 'Order number',
    name: 'Order number',
    selector: 'order_number',
    sortable: false,
  },
  {
    id: 'assignee',
    colLabel: 'Assignee',
    name: 'Assignee',
    selector: 'assignee',
    sortable: false,
  },
  {
    id: 'status',
    colLabel: 'Status',
    name: 'Status',
    selector: 'status',
    sortable: false,
  },
  {
    id: 'actions',
    colLabel: 'Actions',
    name: 'Actions',
    selector: 'actions',
    sortable: false,
  },
];

export const MELEE_SR_SOURCES = [
  {
    label: 'PLATFORM',
    value: 'PLATFORM',
  },
  {
    label: 'APP',
    value: 'APP',
  },
  {
    label: 'ADMIN',
    value: 'ADMIN',
  },
];
// export const MENU_ITEMS = [
//   {
//     id: 'createInventory',
//     label: 'Create Inventory',
//     renderComponent: ({ stockIdLinked, createInventory }) =>
//       !stockIdLinked && (
//         <Button
//           light
//           style={{ padding: '0 !important' }}
//           onClick={createInventory}
//         >
//           {'Create Inventory'}
//         </Button>
//       ),
//   },
//   {
//     id: 'linkInventory',
//     label: 'Link Inventory',
//     renderComponent: ({ isAccepted, stockIdLinked, onLinkInventory }) =>
//       isAccepted && (
//         <>
//           {!stockIdLinked ? (
//             <Button
//               light
//               style={{ padding: '0 !important' }}
//               onClick={onLinkInventory}
//             >
//               {'Link an inventory'}
//             </Button>
//           ) : (
//             <Button
//               light
//               style={{ padding: '0 !important' }}
//               onClick={onLinkInventory}
//             >
//               {'Change Inventory'}
//             </Button>
//           )}
//         </>
//       ),
//   },
// ];

export const MELEE_SR_QUOTE_ESTIMATED_DAYS = [
  {
    label: '2-3',
    value: '2-3',
  },
  { label: '3-4', value: '3-4' },
  { label: '4-5', value: '4-5' },
  { label: '5-8', value: '5-8' },
  { label: '8-10', value: '8-10' },
  { label: '10-12', value: '10-12' },
  { label: '12-14', value: '12-14' },
  { label: '14-20', value: '14-20' },
];
