import { toast } from 'react-hot-toast';
import theme from 'fabric/theme';
import { Icon } from 'fabric/resources/icon';

// adjust to suit need
export const ToastComponent = ({ content, id }) => (
  <span className="toast-wrapper">
    <span className="toast-content">{content}</span>
    <button onClick={() => toast.dismiss(id)} className="cancel-btn">
      <Icon name="small_close" size={12} color={theme.palette.gray700} />
    </button>
  </span>
);

export const CustomToastComponent = ({
  id,
  type,
  hasCustomComponent,
  component,
}) => {
  let content = <></>;
  return (
    <>
      {hasCustomComponent ? (
        component
      ) : (
        <div
          className="custom-toast-component"
          style={{
            background:
              type === 'default'
                ? theme.semanticColors.labelBlueBG
                : type === 'error'
                ? theme.semanticColors.errorBG
                : theme.palette.green50,
            color:
              type === 'default'
                ? theme.semanticColors.labelBlue
                : type === 'error'
                ? theme.semanticColors.error
                : theme.palette.green5,
          }}
        >
          <span
            className="toast-icon"
            style={{
              background:
                type === 'default'
                  ? theme.semanticColors.labelBlue
                  : type === 'error'
                  ? theme.semanticColors.error
                  : theme.palette.green5,
            }}
          >
            <Icon
              name={type === 'error' ? 'small_close' : 'success_mark'}
              size={12}
              color="white"
            />
          </span>
          <ToastComponent content={content} id={id} />
        </div>
      )}
    </>
  );
};

export const dismissToast = () => toast.dismiss();
export const dismissToastById = (id) => toast.dismiss(id);
export const removeToast = (id) => toast.remove(id);

// call function on an event e.g on button click, whenever anywhere
export const customNotification = ({
  id = '',
  component,
  hasCustomComponent = false,
  duration = Infinity,
  position = 'bottom-center',
  type = 'success',
}) =>
  toast.custom(
    <CustomToastComponent
      id={id}
      type={type}
      hasCustomComponent={hasCustomComponent}
      component={component}
    />,
    {
      position,
      duration,
      id,
    }
  );
