import { Popup, Overlay, Tooltip } from '@nivoda/ui';
import { Icon } from 'fabric/resources/icon';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { CarouselWrapper, DownloadPopupWrapper } from './style';
import ZoomSlider from './ZoomSlider';
import { ShortlistButton } from 'shortlist/ShortlistButton';
import { Button } from 'fabric/components';
import { Diamond, downloadImageFile } from 'helpers';
import Axios from 'axios';
import DiamondImage from 'diamond-image/DiamondImage';
import { useErrorWrapper } from 'fabric/hooks';
import {
  getGemsVideoDownloadLink,
  getVideoDownloadLink,
} from 'graphql/queries/common/diamond-asset';
import { theme } from 'fabric/theme';
import { round } from 'lodash/math';
import { AWS360 } from 'diamond-image/AWS360';
import { GiaModal } from '@nivoda/common';
import { isGemstoneItem } from 'utils';
import { featureFlagLS } from 'graphql/factory/featureFlagResolver';

const DOWNLOAD_WAIT_TIME = 15;
const countdown = (seconds = 0, setProgress = () => {}) => {
  if (typeof seconds !== 'number') {
    // eslint-disable-next-line no-console
    console.warn('No value provided for second');
  } else {
    const tick = () => {
      seconds--;
      if (seconds > 0) {
        setProgress(DOWNLOAD_WAIT_TIME - seconds);
        setTimeout(tick, 1000);
      }
    };
    tick();
  }
};

export const DiamondImageModal = ({
  setModal,
  showTicker,
  offerId,
  offer,
  imageModalTicker,
  certificate = {},
  product,
  is_supplier = false,
  hideShortlist = false,
  userInReview,
}) => {
  const is_gemstone = isGemstoneItem(product);
  const assets = { image: certificate.image, v360: certificate.v360 };
  const certificateId =
    certificate.lab === 'NONE' ? null : certificate.certNumber;

  const showMoreMedia =
    !is_supplier && certificate?.nivoda_curated && certificate?.gallery_link;
  const enableNivodaCurated = featureFlagLS.get()?.gemstone_curate_filter;
  const { t } = useTranslation();
  const CancelToken = Axios.CancelToken;
  const transformComponentRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [downloadStatus, setDownloadStatus] = useState({
    source: CancelToken.source(),
  });
  const [fnWrapper, context] = useErrorWrapper({
    showSuccess: false,
    useToastNotification: true,
  });
  const [downloadProgressModal, setDownloadProgressModal] = useState(false);
  const [showCertModal, setShowCertModal] = useState(false);
  const [selected, setSelected] = useState({
    image: (assets?.image && assets?.v360) || assets?.image,
    video: !assets.image && assets.v360,
  });
  const [progress, setProgress] = useState(0);
  const [processing, setProcessing] = useState({
    video_link: null,
    status: '',
  });

  const downloadHandler = (e) => {
    imageModalTicker(
      `${e.status === 'completed' ? t(e.message) : `${t(e.message)}...`} ${
        e && e.percent
      }%`
    );
    if (e.status === 'completed') {
      setDownloadStatus({
        ...downloadStatus,
        source: CancelToken.source(),
      });
      setProcessing({
        video_link: null,
        status: '',
      });
      setProgress(0);
    }
  };
  const onImageDownloadClickHandler = async () => {
    await downloadImageFile({
      cert_number: certificateId,
      downloadHandler: downloadHandler,
      source: downloadStatus.source,
      url: assets.image,
    });
  };

  const downloadVideoFromLink = async (url) => {
    await downloadImageFile({
      cert_number: url.split('/')[url.split('/').length - 1],
      downloadHandler: downloadHandler,
      source: downloadStatus.source,
      type: 'VIDEO',
      url: url,
    });
  };

  const startGenerateVideoReq = async () => {
    let key = is_gemstone
      ? 'get_gemstone_video_download_link'
      : 'get_video_download_link';
    let method = is_gemstone ? getGemsVideoDownloadLink : getVideoDownloadLink;
    await fnWrapper(
      async () => {
        countdown(DOWNLOAD_WAIT_TIME - 0.5, setProgress);
        await setProcessing({ ...processing, status: 'PROCESSING' });
        const { data, loading } = await method(certificate.id);
        if (!loading && data && data[key]) {
          await setProcessing({
            ...processing,
            status: 'COMPLETE',
            video_link: data[key],
          });
          // set percentage to 100%
          await setProgress(DOWNLOAD_WAIT_TIME);
        }
      },
      null,
      {
        error: {
          messageResolver: () => t('generating_video_link_fail'),
        },
        success: {
          messageResolver: () => t('video_link_generated').split(',')[0],
        },
      }
    );
  };

  const onVideoDownloadClickHandler = async () => {
    setDownloadProgressModal(true);
    await startGenerateVideoReq();
  };
  const updateScale = async (e) => {
    const targetScale = parseFloat(e.target.value);
    const factor = Math.log(targetScale / scale);
    const { zoomIn, zoomOut } = transformComponentRef.current;

    if (targetScale > scale) {
      zoomIn(factor, 0);
    } else {
      zoomOut(-factor, 0);
    }

    setScale(targetScale);
  };

  return (
    <>
      {context.responseJSX}
      {!showCertModal && (
        <Overlay>
          <CarouselWrapper isDiamond={true}>
            <Popup
              onClose={() => setModal(false)}
              remove_top_margin={true}
              hide_title={true}
              showBorder={false}
              className="inline_crousel"
            >
              <div
                className="inline_crousel_box"
                style={{
                  minWidth: '600px',
                  maxWidth: '800px',
                  width: 'fit-content',
                }}
              >
                <div className="crousel_media_header">
                  <div className="left-box">
                    {!is_supplier && !hideShortlist && (
                      <ShortlistButton
                        custom_style={{
                          height: '30px',
                          width: '30px',
                          padding: '0',
                          borderRadius: '50%',
                          defaultIconColor: '#000',
                          defaultBgColor: 'transparent',
                          defaultBorderColor: 'transparent',
                          activeIconColor: '#000',
                          activeBgColor: '#E9F2FD',
                          activeBorderColor: '#c2dbfc',
                        }}
                        offerId={offerId}
                        offer={offer}
                        section={'custom'}
                        is_new={true}
                        //ToolTip placement
                        placement={'right'}
                        displayText={false}
                        onClick={() => {
                          showTicker();
                          // eslint-disable-next-line no-console
                          console.log('We have added to shortlist');
                        }}
                      />
                    )}
                  </div>
                  <Tooltip placement={'bottom'} content={t('close')}>
                    <div className="right-box">
                      <Icon
                        name="close"
                        size={12}
                        className="close-button-icon"
                        onClick={() => setModal(false)}
                      />
                    </div>
                  </Tooltip>
                </div>
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                  className="crousel_media_image"
                  // onMouseEnter={() => setIsHovering(true)}
                  // onMouseOut={() => setIsHovering(false)}
                >
                  {selected.image && (
                    <TransformWrapper
                      // panning={{
                      //   velocityDisabled: true,
                      //   disabled: true,
                      // }}
                      ref={transformComponentRef}
                      initialScale={scale}
                      minScale={1}
                      maxScale={4}
                      doubleClick={{
                        disabled: true,
                      }}
                      limitToBounds={false}
                      zoomAnimation={{ disabled: true }}
                      centerOnInit
                      centerZoomedOut={true}
                      onZoom={(e) => {
                        setScale(e.state.scale);
                      }}
                    >
                      {({ zoomIn, zoomOut }) => (
                        <>
                          <TransformComponent>
                            <img
                              src={assets.image}
                              alt="diamond"
                              style={{
                                objectFit: 'contain',
                                height: 510,
                                width: 500,
                              }}
                            />
                          </TransformComponent>
                          <div className="zoom-component">
                            <ZoomSlider
                              updateScale={updateScale}
                              value={scale}
                              className="slider"
                              zoomIn={() => {
                                zoomIn();
                                setScale((scale) =>
                                  scale < 5 ? scale + 1 : scale
                                );
                              }}
                              zoomOut={() => {
                                zoomOut();
                                setScale((scale) =>
                                  !(scale <= 1) ? scale - 1 : scale
                                );
                              }}
                            />
                          </div>
                        </>
                      )}
                    </TransformWrapper>
                  )}
                  {selected.video && (
                    <AWS360
                      v360_info={assets.v360}
                      animate={true}
                      variant={'INLINE_CAROUSEL'}
                    />
                  )}
                </div>
                <div className="crousel_media_thumbes">
                  {assets.image && (
                    <div
                      className={`crousel_media_item ${
                        selected.image && 'active-item'
                      }`}
                    >
                      <DiamondImage
                        styles={{
                          width: '100%',
                          borderRadius: '5px',
                          objectFit: 'cover',
                        }}
                        imageUrl={assets.image}
                        onClick={() =>
                          setSelected({
                            ...selected,
                            image: true,
                            video: false,
                          })
                        }
                      />
                    </div>
                  )}
                  {assets.v360 && (
                    <div
                      className={`crousel_media_item ${
                        selected.video && 'active-item'
                      }`}
                    >
                      <DiamondImage
                        styles={{
                          width: '100%',
                          borderRadius: '5px',
                          objectFit: 'cover',
                        }}
                        url360={assets.v360}
                        small={true}
                        onClick={() =>
                          setSelected({
                            ...selected,
                            image: false,
                            video: true,
                          })
                        }
                      />
                    </div>
                  )}
                  {enableNivodaCurated && showMoreMedia && (
                    <div
                      className="crousel_media_item more-media"
                      onClick={() => {
                        const url = certificate?.gallery_link;
                        window.open(url, '_blank');
                      }}
                    >
                      <div className="more-media-label">
                        {t('click_for_hand_and_tweezer_videos')}{' '}
                        <Icon
                          name={'external_link'}
                          size={10}
                          color={theme.palette.themePrimary}
                          style={{ marginLeft: 2 }}
                        />
                      </div>
                    </div>
                  )}
                  {certificateId && !userInReview && (
                    <div
                      className="crousel_media_item cert-center"
                      onClick={() => {
                        if (certificate.lab === 'IGI') {
                          const url = certificate.pdfUrl
                            ? certificate.pdfUrl
                            : `https://www.igi.org/reports/verify-your-report?r=${certificate.certNumber}`;
                          window.open(url, '_blank');
                        } else if (certificate.lab === 'HRD') {
                          const url = certificate.pdfUrl
                            ? certificate.pdfUrl
                            : `https://my.hrdantwerp.com/?record_number=${certificate.certNumber}`;
                          window.open(url, '_blank');
                        } else {
                          setShowCertModal(!showCertModal);
                        }
                      }}
                    >
                      <div className="eyeclean-cert">
                        <Icon name="cert-v2" />
                        <label>Cert.</label>
                      </div>
                    </div>
                  )}
                </div>
                {assets.image && (
                  <div className="crousel_media_actions">
                    <Button
                      className="action_button"
                      disabled={false}
                      onClick={async () => {
                        // navigator.clipboard.writeText(assets.image);
                        await navigator.clipboard.writeText(
                          `https://loupe360.com/${
                            is_gemstone ? 'gemstone' : 'diamond'
                          }/${certificate.id}/img/500/500`
                        );
                        imageModalTicker(t('image_link_copied'));
                      }}
                    >
                      <Icon name="link-icon" size={16} />
                      {t('copy_image_link')}
                    </Button>
                    <Button
                      className="action_button"
                      disabled={false}
                      onClick={() => onImageDownloadClickHandler()}
                    >
                      <Icon
                        name="download-2"
                        size={16}
                        color={theme.palette.themePrimary}
                      />
                      {t('download_image')}
                    </Button>
                  </div>
                )}
                {assets.v360 && (
                  <div className="crousel_media_actions">
                    <Button
                      className="action_button"
                      disabled={false}
                      onClick={async () => {
                        await navigator.clipboard.writeText(
                          `https://loupe360.com/${
                            is_gemstone ? 'gemstone' : 'diamond'
                          }/${certificate.id}/video/500/500/autoplay`
                        );

                        imageModalTicker(t('video_link_copied'));
                      }}
                    >
                      <Icon name="link-icon" size={16} />
                      {t('copy_video_link')}
                    </Button>
                    <Button
                      className="action_button"
                      disabled={false}
                      onClick={onVideoDownloadClickHandler}
                    >
                      <Icon
                        name="download-2"
                        size={16}
                        color={theme.palette.themePrimary}
                      />
                      {t('download_video')}
                    </Button>
                  </div>
                )}
              </div>
            </Popup>
          </CarouselWrapper>
        </Overlay>
      )}
      {processing.status !== '' && downloadProgressModal && (
        <DownloadProgressModal
          progress={progress}
          setDownloadProgressModal={setDownloadProgressModal}
          processing={processing}
          downloadVideoFromLink={downloadVideoFromLink}
          certificateId={certificateId}
          product={product}
          is_supplier={is_supplier}
        />
      )}
      {showCertModal && (
        <GiaModal
          url={certificate.pdfUrl}
          lab={certificate.lab}
          giaNumber={certificate.certNumber}
          onClick={() => {
            setShowCertModal(!showCertModal);
          }}
        />
      )}
    </>
  );
};

export const DownloadProgressModal = ({
  progress,
  setDownloadProgressModal,
  processing,
  downloadVideoFromLink,
  certificateId,
  product,
  is_supplier = false,
}) => {
  const { t } = useTranslation();
  return (
    <Overlay>
      <Popup
        onClose={() => {
          setDownloadProgressModal(false);
        }}
      >
        <DownloadPopupWrapper>
          {processing.status === 'PROCESSING' && (
            <>
              <div className="loadingBG">
                <Icon
                  className={'timer'}
                  color={theme.palette.themePrimary}
                  name={'sand-clock'}
                  size={20}
                />
              </div>
              <div className="download-title">{t('generating_link')}</div>
              <div className="progress-text">
                <Icon
                  name={'loader'}
                  color={theme.palette.themePrimary}
                  size={16}
                />
                <span>
                  {t('processing_video_for_download')}...
                  {round((progress / DOWNLOAD_WAIT_TIME) * 100)}%
                </span>
              </div>
            </>
          )}
          {processing.status === 'COMPLETE' && (
            <>
              {processing.video_link !== null &&
              !processing.video_link.includes('Incorrect') ? (
                <>
                  <div className="successBG">
                    <Icon
                      aria-hidden="true"
                      color={theme.semanticColors.success}
                      name={'check-icon'}
                      size={26}
                    />
                  </div>
                  <div className="download-title">
                    {` ${t('video_link_for')}${
                      !is_supplier
                        ? Diamond.get_stock_id(product, {
                            returnPlainText: true,
                          })
                        : t('video')
                    }`}
                  </div>
                  <div>{t('video_link_generated')}</div>
                  <Button
                    className="action_button"
                    onClick={() => downloadVideoFromLink(processing.video_link)}
                  >
                    <Icon
                      name="download-2"
                      size={16}
                      color={theme.palette.themePrimary}
                    />
                    {t('download_video')}
                  </Button>
                </>
              ) : (
                <>
                  <div className="errorBG">
                    <Icon
                      aria-hidden="true"
                      color={theme.semanticColors.error}
                      name={'error_icon'}
                      size={26}
                    />
                  </div>
                  <div className="download-title">
                    {t('video_link_for')} {certificateId}
                  </div>
                  <div>{t('incorrect_certificate')}</div>
                </>
              )}
            </>
          )}
        </DownloadPopupWrapper>
      </Popup>
    </Overlay>
  );
};
