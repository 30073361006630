import { BaseCertificateFragment } from 'graphql/fragments/CertificateFragment';
import { AdminGraphqlQuery } from '../../../graphql-admin';
import {
  AdminFullOrderItemFragment,
  // AdminOrderLongInfoFragment,
  CertificateInfoFragment,
  ChildShipmentFragment,
  GemstoneCertificate,
  MeleeFragment,
  NivodaExpressItemFragment,
  NoteFragment,
  // MeleeFragment,
  OfficeToShipmentFragment,
  SbsChildShipmentFragment,
} from '../../fragments';
import { gql } from '@apollo/client';
import { process_gemstone_data } from 'Helper/processGemstoneQuery';
import { AdminOrderItemCShipmentFragment } from 'graphql/fragments/OrderItemFragment';

let parse_gemstone = (data, key) => {
  let old_items = (data[key] != null && data[key]?.items) || [];

  if (data[key] == null) {
    return data;
  }
  let items = data[key] != null && process_gemstone_data(old_items);

  return {
    ...data,
    [key]: {
      ...data[key],
      items: items,
    },
  };
};
export const GET_CONSOLIDATED_SHIPMENT_DETAILS = gql`
  query getConsolidatedShipmentDetails($id: ID!) {
    admin_shipment_by_id(shipment_id: $id) {
      id
      status
      shipment_method
      inter_office_shipment
      shipment_status
      shipment_created
      return_sales_invoice
      consignment
      markup_item
      markup_amount
      gross_weight
      net_weight
      tin_boxes
      shipping_invoice_number
      shipping_invoice_number_lg
      envelope_number
      box_type
      box_size
      consignment_basic
      consignment_FEMA
      consignment_final
      pph_insurance_form
      combine_insurance_label
      irn
      acknowledgement_date
      acknowledgement_number
      shipment_config
      ic_invoice_id
      parent_shipment {
        id
        carrier
        status
        shipment_status
        shipment_created
        bulk_break
        box_type
      }
      child_shipments {
        ...ChildShipment
        items {
          id
          current_location {
            id
            country
          }
          status
        }
      }
      carrier
      service_type
      intl
      nivoda_shipment
      tracking_code
      label_downloaded
      break_bulk_excel
      india_customs_sheet
      bulk_break
      shipment_created
      shipment_delivered
      label
      insurance_label
      label_page_one
      commercial_invoice
      commercial_invoice_lg
      delivery_note
      package_slip
      brinks_sheet
      has_qc_items
      fbn_shipment
      consignment_mailed
      shipping_bill
      shipping_bill_date
      declaration_certificate
      items {
        ...AdminOrderItemCShipmentFragment
        current_location {
          id
          country
        }
        shipments {
          id
          destination {
            id
          }
          tracking_code
          is_return_shipment
          status
        }
      }
      destination {
        id
        address1
        postalCode
        city
        state
        name
        country
        owner {
          id
          company {
            id
            name
            waybill_name
          }
        }
      }
      origin {
        id
        address1
        postalCode
        city
        name
        country
        state
        company {
          id
          name
        }
        owner {
          id
          company {
            id
            name
          }
        }
      }
    }
  }
  ${AdminOrderItemCShipmentFragment}
  ${ChildShipmentFragment}
`;
export const GetConsolidatedShipmentDetails = AdminGraphqlQuery({
  query: GET_CONSOLIDATED_SHIPMENT_DETAILS,
});

const SalesInvoiceReturnedOrderItems = gql`
  fragment SalesInvoiceReturnedOrderItems on AdminShipmentReturnedStones {
    order_items {
      ...AdminOrderItemCShipmentFragment
      current_location {
        id
        country
      }
      status
      shipments {
        id
        shipment_method
        destination {
          id
          country
        }
        origin {
          id
          country
        }
        fresh_import
        tracking_code
        status
      }
    }
    total_count
  }
  ${AdminOrderItemCShipmentFragment}
`;

export const GET_RETURNED_STONES_DETAILS = gql`
  query getReturnedStonesDetails($id: ID!) {
    admin_shipment_returned_stones(shipment_id: $id) {
      ...SalesInvoiceReturnedOrderItems
    }
  }
  ${SalesInvoiceReturnedOrderItems}
`;

export let IsSupplierShipment = AdminGraphqlQuery({
  query: gql`
    query getShipmentDetails($id: ID!) {
      admin_shipment_by_id(shipment_id: $id) {
        id
        supplier_shipment
        supplier_return_shipment
        origin {
          office_code
        }
        destination {
          office_code
        }
      }
    }
  `,
});

// get shipment details by shipment id
export let GetShipmentDetails = AdminGraphqlQuery({
  query: gql`
    query getShipmentDetails($id: ID!) {
      admin_shipment_by_id(shipment_id: $id) {
        id
        inter_office_shipment
        status
        shipment_method
        shipment_status
        shipment_created
        box_type
        tin_boxes
        etd_shipment
        pph_insurance_form
        consignment
        fresh_import
        combine_insurance_label
        fbn_shipment
        last_mile_document
        last_mile_shipment
        customer_invoice_id
        import_return_statement
        proforma_invoice
        return_sales_invoice
        final_sales_invoice
        ic_invoice_id
        shipping_invoice_number
        consignment_upload
        shipping_bill
        shipping_bill_date
        customer_return_shipment
        supplier_shipment
        parent_shipment {
          id
          carrier
          status
          shipment_status
          shipment_created
          box_type
        }
        child_shipments {
          id
          carrier
          status
          shipment_status
          shipment_created
          box_type
        }
        carrier
        service_type
        intl
        nivoda_shipment
        transit_shipment
        tracking_code
        shipment_delivered
        insurance_label
        label
        label_page_one
        commercial_invoice
        commercial_invoice_lg
        delivery_note
        shipper_declaration
        package_slip
        brinks_sheet
        india_customs_sheet
        consignment_basic
        consignment_FEMA
        consignment_final
        memo_note
        gross_weight
        net_weight
        is_return_shipment
        supplier_return_shipment
        consignment_mailed
        jewellery_supplier_shipment
        customer_return_shipment
        declaration_certificate
        ...OfficeToShipment
        items {
          id
          current_location {
            id
            country
          }
        }
        origin {
          id
          address1
          address2
          postalCode
          city
          name
          country
          state
          suburb
          office_code
          company {
            id
            name
          }
          owner {
            id
            company {
              id
              name
            }
          }
        }
        scheduled_pickup {
          id
          ShipmentId
          status
          requestedBy
          requestedAt
          pickupDate
          startTime
          endTime
          confirmCode
          errorCode
        }
      }
    }
    ${AdminFullOrderItemFragment}
    ${OfficeToShipmentFragment}
  `,
  map_data: (data) => {
    let key = 'admin_shipment_by_id';
    return parse_gemstone(data, key);
    //   let old_items = (data[key] != null && data[key]?.items) || [];

    //   if (data[key] == null) {
    //     return data;
    //   }
    //   let items = data[key] != null && process_gemstone_data(old_items);

    //   return {
    //     ...data,
    //     [key]: {
    //       ...data[key],
    //       items: items,
    //     },
    //   };
  },
});

export const GetOfficeToShipmentDetails = AdminGraphqlQuery({
  query: gql`
    query getOfficesToShipmentDetails($id: ID!) {
      admin_shipment_by_id(shipment_id: $id) {
        ...OfficeToShipment
        items {
          Qc {
            id
            status
          }
          QcGemstone {
            id
            status
          }
          assigned_to {
            id
            firstName
            lastName
          }
        }
      }
    }
    ${AdminFullOrderItemFragment}
    ${OfficeToShipmentFragment}
  `,
  map_data: (data) => {
    let key = 'admin_shipment_by_id';
    return parse_gemstone(data, key);
  },
});

export const GetEnvelopeDetails = AdminGraphqlQuery({
  query: gql`
    query getEnvelopeByShipmentID($shipment_id: ID!, $envelope: String!) {
      admin_envelope_by_shipment_id(
        shipment_id: $shipment_id
        envelope: $envelope
      ) {
        items {
          id
          buyer {
            id
            company {
              id
              company_settings {
                CompanyId
                company_code
              }
            }
          }
          internal_order
          parent_order_item {
            id
            order_number
            shipping_reference_number
          }
          order_number
          origin_city
          sequence_number
          labgrown_item
          ProductType
          base_sell_price
          buy_price
          carats
          pieces
          Product {
            ... on Diamond {
              id
              certificate {
                id
                certNumber
                lab
                shape
                carats
                labgrown
              }
            }
            ... on Gemstone {
              id
              GemstoneCertificate: certificate {
                id
                lab
                gemType
                certNumber
                shape
                carats
                color
                cut
                clarity
              }
            }
            ... on Melee {
              id
              MeleeCertificate: certificate {
                id
                color
                cut
                clarity
                treated
                calibrated
                shape
                to_mm_size
                to_sieve_size
                from_mm_size
                from_sieve_size
              }
              IsParentMeleeCollection
              stones_per_carat
              supplierStockId
              carats
              pieces
              type
              parcel
              supplier {
                id
                name
              }
            }
          }
          shipping_reference_number
        }
        shipment {
          id
          status
          address_printed
          tracking_code
          scanned_items
          destination {
            id
            postalCode
            address1
            city
            country
            state
            address2
            name
            company {
              id
              name
              waybill_name
            }
            owner {
              id
              company {
                id
                name
                waybill_name
              }
            }
          }
        }
      }
    }
  `,
  map_data: (data) => {
    let key = 'admin_envelope_by_shipment_id';
    return parse_gemstone(data, key);
  },
});

export const GET_ENVELOPE_OVERVIEW = gql`
  query getEnvelopeOverview($envelopeTitle: String!) {
    data: get_envelope_details_by_envelope_title(
      envelope_title: $envelopeTitle
    ) {
      id
      status
      shipment_status
      envelope_number
      createdAt
      envelope_id
      envelope_barcode {
        barcode_text
      }
      items {
        id
      }
      origin {
        id
        city
      }
      parent_shipment {
        status
        createdAt
        origin {
          id
          city
        }
        destination {
          id
          city
        }
      }
      destination {
        id
        address1
        address2
        city
        state
        country
        company_name
        kyc_verified
        company {
          name
        }
      }
    }
  }
`;
export const GET_ENVELOPE_DETAILS = gql`
  query getEnvelopeDetails($envelopeTitle: String!) {
    data: get_envelope_details_by_envelope_title(
      envelope_title: $envelopeTitle
    ) {
      id
      items {
        id
        order {
          id
          user_comment
          order_date
        }
        SupplierRaisedIssue {
          id
          reason
          issueType
          orderItemId
          isConfirmed
        }
        csp
        status
        order_number
        base_sell_price
        base_sell_discount
        origin_country
        origin_city
        buy_price_confirmed
        nivoda_markup
        transit_country
        promisedDeliveryDate
        buy_price
        buy_discount
        canada_mark_required
        dtl_required
        confirmed_by_supplier
        deliveryDeadline
        deliveryDate
        expectedDeliveryDate
        shippingDate
        expectedShippingDate
        expectedCollectionDate
        collectedDate
        transitDate
        wdcComment
        comment
        customer_order_number
        customer_comment
        supplier_paid
        return_option
        return_percentage_charge
        rupee_rate
        waiting_for_customer
        part_of_gia_request
        part_of_first_purchase
        request_source
        updatedAt
        wfc_start
        wfc_end
        to_PO_time
        estim_confm_time
        issues {
          id
          OrderItemId
          reason {
            id
            type
            text
          }
          order_item_status
          createdAt
        }
        self_confirm_status
        self_confirm_bgmec_value
        supplier_api_triggered
        FbnId
        FbnType
        destination {
          id
          country
        }
        shipments {
          id
          tracking_code
        }
        buyer {
          id
          firstName
          company {
            id
            name
            qc_pref
            company_settings {
              CompanyId
              company_code
              customer_order_no_on_bc
            }
          }
        }
        invoice {
          id
          invoice_number
          type
          settled
        }
        supplier_invoice {
          id
        }
        los_supplier_paid {
          text
          status
        }
        Product {
          ... on Diamond {
            id
            supplierStockId
            diamondSupplierStockId
            bowtie
            brown
            green
            blue
            gray
            milky
            other
            eyeClean
            availability
            last_enquiry_answered
            has_bgmec_enquiry
            has_media_enquiry
            offer {
              id
              price
              discount
              price_per_carat
              product_price
              product_discount
              return_price
              return_discount
            }
            certificate {
              ...BaseCertificate
            }
            location {
              id
              name
              country
            }
            supplier {
              id
              name
              website
              country
              accept_returns
              supplier_auto_shipment_creation
              company_settings {
                CompanyId
                final_price
                website_order_placement
                hk_only_delivery
                in_with_charges_delivery
                communicates_through_email
                communication_email
                in_delivery_upto
                in_delivery_upto_amount
                self_serve_po
                supplier_based_comment
              }
              payment_terms {
                id
                payment_term_type
                location
              }
            }
          }
          ... on Melee {
            ...MeleeFragment
            child_melee_collections {
              ...MeleeFragment
              supplierStockId
              total_price
              price_per_carat
              IsParentMeleeCollection
            }
            total_price
            price_per_carat
            IsParentMeleeCollection
          }
          ... on Gemstone {
            id
            supplierStockId
            availability
            last_enquiry_answered
            has_bgmec_enquiry
            has_media_enquiry
            GemstoneCertificate: certificate {
              id
              ...GemstoneCertificate
            }
            gemstoneSupplierStockId
            location {
              id
              name
              country
            }
            supplier {
              id
              name
              website
              country
              accept_returns
              supplier_auto_shipment_creation
              company_settings {
                CompanyId
                final_price
                website_order_placement
                hk_only_delivery
                in_with_charges_delivery
                communicates_through_email
                communication_email
                in_delivery_upto
                in_delivery_upto_amount
                self_serve_po
                supplier_based_comment
              }
              payment_terms {
                id
                payment_term_type
                location
              }
            }
          }
        }
        ProductType
        assigned_to {
          id
          firstName
          lastName
          profile_image
        }
        notes {
          ...AdminNote
        }
        qc_customer_status
        Qc {
          id
          status
        }
      }
    }
  }
  ${BaseCertificateFragment}
  ${MeleeFragment}
  ${NoteFragment}
  ${GemstoneCertificate}
`;

export const GetFbnIncomingShipmentsList = AdminGraphqlQuery({
  query: gql`
    query getFBNIncomingShipments(
      $origin_country: String!
      $origin_city: OriginCity
    ) {
      fbn_incoming_shipments_overview(
        origin_country: $origin_country
        origin_city: $origin_city
      ) {
        shipments {
          id
          tracking_code
          origin {
            id
            country
            name
          }
          destination {
            id
            country
            name
          }
          items {
            id
            order_number
            buy_price
            Product {
              ... on Diamond {
                id
                supplierStockId
                supplier {
                  id
                }
                certificate {
                  ...CertificateInfo
                }
              }
            }
          }
        }
        total_shipments
        total_stones
        total_shipment_value
      }
    }
    ${CertificateInfoFragment}
  `,
});

export const GetFBNShippingDestinations = AdminGraphqlQuery({
  query: gql`
    query ($origin_id: ID!) {
      addable_fbn_shipment_items(origin_id: $origin_id) {
        destination_country
        items_count
        items {
          id
          buy_price
          destination {
            id
            name
            country
            city
          }
        }
        shipment_total
      }
    }
  `,
});

export const ADMIN_GET_EXPRESS_READY_TO_SHIP_ITEMS = gql`
  query ($query: express_internal_rts_query, $limit: Int, $offset: Int) {
    express_internal_rts_items(query: $query, limit: $limit, offset: $offset) {
      items {
        id
        buyer {
          id
          firstName
          company {
            id
            name
            company_settings {
              CompanyId
              company_code
              customer_order_no_on_bc
            }
            qc_pref
          }
        }
        buy_price
        buy_discount
        buy_price_confirmed
        destination {
          id
          country
        }
        FbnId
        FbnType
        FbnItem {
          ...NivodaExpressItemFragment
        }
        order {
          id
        }
        origin_country
        origin_city
        order_number

        Product {
          ... on Diamond {
            id
            availability
            supplierStockId
            diamondSupplierStockId
            certificate {
              ...BaseCertificate
            }
            supplier {
              id
              name
              country
              company_settings {
                CompanyId
                final_price
              }
            }
          }
          ... on Melee {
            ...MeleeFragment
            child_melee_collections {
              ...MeleeFragment
              supplierStockId
              total_price
              price_per_carat
              IsParentMeleeCollection
            }
            total_price
            price_per_carat
            IsParentMeleeCollection
          }
          ... on Gemstone {
            id
            availability
            GemstoneCertificate: certificate {
              id
              ...GemstoneCertificate
            }
            gemstoneSupplierStockId
            supplier {
              id
              name
              country
              company_settings {
                CompanyId
                final_price
              }
            }
            supplierStockId
          }
        }
        ProductType
        status
      }
      count
    }
  }
  ${BaseCertificateFragment}
  ${NivodaExpressItemFragment}
  ${MeleeFragment}
  ${GemstoneCertificate}
`;

export const ADMIN_EXPRESS_READY_TO_SHIP_FILTER_DATA = gql`
  query ($query: express_internal_rts_query) {
    express_internal_rts_items(query: $query) {
      Suppliers {
        id
        company {
          id
          name
          country
        }
      }
      Buyers {
        id
        firstName
        lastName
      }
    }
  }
`;

export let GetQCPassedItems = AdminGraphqlQuery(
  gql`
    query ($country: String, $customer_id: ID, $city: String) {
      admin_get_qc_passed_items(
        country: $country
        customer_id: $customer_id
        city: $city
      ) {
        id
        order_number
        order {
          id
        }
        buyer {
          id
          company {
            id
            name
            country
          }
        }
        destination {
          id
          country
        }
        ProductType
        Product {
          ... on Diamond {
            id
            certificate {
              id
              lab
              certNumber
            }
          }
          ... on Melee {
            id
            MeleeCertificate: certificate {
              id
              certNumber
            }
          }
          ... on Gemstone {
            id
            GemstoneCertificate: certificate {
              id
              lab
              certNumber
            }
          }
        }
      }
    }
  `
);

export let ADMIN_ALL_SHIPMENTS = gql`
  query adminAllShipments(
    $origin_country: OriginCountry
    $offset: Int
    $from_date: String
    $to_date: String
    $from_consig_end_date: String
    $to_consig_end_date: String
    $tracking_code: String
    $shipment_type: String
    $status: String
    $limit: Int
    $customer_company_id: ID
    $supplier_company_id: ID
  ) {
    all_shipments(
      offset: $offset
      limit: $limit
      origin_country: $origin_country
      from_date: $from_date
      to_date: $to_date
      from_consig_end_date: $from_consig_end_date
      to_consig_end_date: $to_consig_end_date
      tracking_code: $tracking_code
      shipment_type: $shipment_type
      status: $status
      customer_company_id: $customer_company_id
      supplier_company_id: $supplier_company_id
    ) {
      total_count
      items {
        id
        bulk_break
        inter_office_shipment
        tracking_code
        carrier
        shipment_method
        consignment
        shipment_created
        service_type
        shipment_created
        nivoda_shipment
        transit_shipment
        shipment_delivered
        shipping_invoice_number
        pph_insurance_form
        insurance_label
        combine_insurance_label
        is_return_shipment
        supplier_return_shipment
        supplier_shipment
        customer_return_shipment
        customer_shipment
        intercompany_shipment
        fresh_import
        amount_of_stones
        value_of_stones
        origin {
          id
          address1
          address2
          postalCode
          city
          state
          name
          country
          phone1
          office_code
          company {
            id
            name
          }
          owner {
            id
            company {
              id
              name
            }
          }
        }
        destination {
          id
          address1
          address2
          postalCode
          city
          name
          state
          country
          phone1
          office_code
          company {
            id
            name
          }
          owner {
            id
            firstName
            lastName
            email
            phone1
            company {
              id
              name
            }
          }
        }
        fbn_shipment
        intl
        status
      }
    }
  }
`;

export const GetShipmentJobByLocation = AdminGraphqlQuery({
  query: gql`
    query getShipmentJobByLocation(
      $location: String!
      $origin_city: OriginCity
    ) {
      get_shipment_job_by_location(
        location: $location
        origin_city: $origin_city
      ) {
        id
        location
        status
        errorMessage
        createdAt
        startedBy {
          id
          firstName
          lastName
        }
      }
    }
  `,
});

export const GET_SBS_CONSOLIDATED_SHIPMENT_DETAILS = gql`
  query getSbsConsolidatedShipmentDetails($id: ID!) {
    admin_shipment_by_id(shipment_id: $id) {
      id
      status
      shipment_method
      inter_office_shipment
      shipment_status
      shipment_created
      return_sales_invoice
      consignment
      markup_item
      markup_amount
      gross_weight
      net_weight
      tin_boxes
      shipping_invoice_number
      envelope_number
      box_type
      box_size
      consignment_basic
      consignment_FEMA
      consignment_final
      pph_insurance_form
      combine_insurance_label
      irn
      acknowledgement_date
      acknowledgement_number
      shipment_config
      carrier
      service_type
      intl
      nivoda_shipment
      tracking_code
      label_downloaded
      break_bulk_excel
      india_customs_sheet
      bulk_break
      shipment_created
      shipment_delivered
      label
      insurance_label
      label_page_one
      commercial_invoice
      commercial_invoice_lg
      package_slip
      brinks_sheet
      fbn_shipment
      consignment_mailed
      shipping_bill
      shipping_bill_date
      createdAt
      declaration_certificate
      destination {
        id
        address1
        postalCode
        city
        state
        name
        country
        owner {
          id
          company {
            id
            name
            waybill_name
          }
        }
      }
      origin {
        id
        address1
        postalCode
        city
        name
        country
        state
        company {
          id
          name
        }
        owner {
          id
          company {
            id
            name
          }
        }
      }
      child_shipments {
        ...ChildShipment
      }
    }
  }
  ${SbsChildShipmentFragment}
`;

export const CHECK_ITEMS_STATUS = gql`
  query checkItemsStatus($order_numbers: [String!]!) {
    admin_check_items_status(order_numbers: $order_numbers) {
      id
      order_number
      Qc {
        id
        status
      }
      QcGemstone {
        id
        status
      }
    }
  }
`;

export const GET_CSHIPMENT_ORIGIN_DETAILS = gql`
  query getCshipmentOriginDetails($shipment_id: ID!) {
    admin_shipment_by_id(shipment_id: $shipment_id) {
      id
      origin {
        id
        country
      }
    }
  }
`;
