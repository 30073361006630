import { useState, useEffect, useRef } from 'react';
import { useOnClickOutside } from 'fabric/hooks';
import { Icon } from '@nivoda/ui';
import { useTranslation } from 'react-i18next';
import { customNotification, removeToast } from 'UI/CustomNotification';
import { CopyWrapper } from './style';

// To prevent propagation on the parent, you need to target the row you want to prevent
// pass a parentClassName to the items you want to prevent propagation on.
/**
 * @param {{items: Array, parentClassName: (string | null), copyStyleOverride: string}} param
 * @returns JSX.Element
 */
export const CopyInformation = ({
  items,
  parentClassName,
  copyStyleOverride,
}) => {
  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [copiedValue, setCopiedValue] = useState('');
  const { t } = useTranslation();

  const toggleCopyModal = () => {
    setOpen(true);
  };
  const directCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(items[0]?.value);
    setCopiedValue(items[0]?.value);
    setIsCopied(true);
    // setTimeout(() => {
    //   setOpen(false);
    // }, 500);
  };
  useEffect(() => {
    /**
     * disables click on parent element and parent's sibling elements
     */
    const parentElements = document.getElementsByClassName(parentClassName);
    const arrayOfParentElements = parentElements
      ? Array.prototype.slice.call(parentElements)
      : null;
    if (open) {
      if (arrayOfParentElements) {
        for (let i = 0; i < arrayOfParentElements.length; i++) {
          arrayOfParentElements[i]?.classList?.add('disable-click'); // add this class to css file --> pointer-events: none;
        }
      }
    } else {
      if (arrayOfParentElements) {
        for (let i = 0; i < arrayOfParentElements.length; i++) {
          arrayOfParentElements[i]?.classList?.remove('disable-click'); // remove class
        }
      }
    }
    return () => {
      if (arrayOfParentElements) {
        for (let i = 0; i < arrayOfParentElements.length; i++) {
          arrayOfParentElements[i]?.classList?.remove('disable-click'); // remove class
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (isCopied) {
      customNotification({
        id: 'copy-invoice-number',
        position: 'top-right',
        duration: 2000,
        component: (
          <div
            className={'_toast'}
            data-before={copiedValue}
            data-after={`copied`}
          />
        ),
        hasCustomComponent: true,
      });
    } else {
      removeToast('copy-invoice-number');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCopied]);
  useOnClickOutside(
    modalRef,
    () => {
      setOpen(false);
    },
    { noHandler: false }
  );
  let clickFunc = items.length === 1 ? directCopy : toggleCopyModal;

  return (
    <CopyWrapper
      open={open}
      copyStyleOverride={copyStyleOverride}
      className="copy-wrapper"
    >
      <button
        className={`icon ${open ? `opened` : ``}`}
        onClick={clickFunc}
        onMouseLeave={() => {
          if (items.length === 1) {
            setIsCopied(false);
          }
        }}
      >
        <Icon name={'copyIcon'} size={14} />
        <span data-id={'copy-text'}>{t('copy')}</span>
      </button>

      <div
        className={`info ${open ? `open` : ``}`}
        ref={modalRef}
        style={{ pointerEvents: 'auto' }}
      >
        {items.map((item, idx) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(item?.value);
              setIsCopied(true);
              setCopiedValue(item?.value);
              setTimeout(() => {
                setOpen(false);
              }, 500);
            }}
            onMouseLeave={() => {
              setIsCopied(false);
              setCopiedValue('');
            }}
            key={`${item.label}-${idx}`}
          >
            <h6>{t(item?.label)}</h6>
            <span>{item?.value}</span>
          </div>
        ))}
      </div>
    </CopyWrapper>
  );
};
