import theme from 'fabric/theme';
import styled from 'styled-components';

export const CancelDiamondRequestPopupWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  /* position: relative; */
  .close_btn_section {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
  .cancel_popup_title {
    ${theme.fonts.xxLargeBolder}
    line-height: 24px;
    color: ${theme.palette.Neutrals950};
    padding-bottom: 4px;
  }
  .cancel_popup_content {
    &--title {
      ${theme.fonts.large}
      color:${theme.palette.themePrimary}
    }
  }
  .cancel_popup_footer {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    .no_btn {
      border-radius: 5px;
      padding: 15px 20px !important;
      display: flex;
      align-items: center;
      border: 1px solid ${theme.palette.Neutrals300};
      background: ${theme.palette.white};
      ${theme.fonts.smallBolder}
      color:${theme.palette.Neutrals700};
      :hover {
        border: 1px solid ${theme.palette.blueStoke};
        background: ${theme.palette.white};
        color: ${theme.palette.themeSecondary};
        box-shadow: none;
      }
    }
    .yes_btn {
      display: flex;
      align-items: center;
      gap: 5px;
      border-radius: 5px;
      padding: 15px 20px !important;
      ${theme.fonts.smallBolder}
      :hover {
        box-shadow: none;
        background: ${theme.palette.brandBlue4};
      }
    }
  }
`;
