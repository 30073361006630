import { gql } from '@apollo/client';

export const CertificateMiniFragment = gql`
  fragment CertificateMini on Certificate {
    id
    certNumber
    cut
    shape
    carats
    color
    clarity
    floCol
    floInt
    lab
    v360 {
      top_index
      frame_count
      url
      renumbered
    }
    pdfUrl
    image
  }
`;

export const CertificateMiniInfoFragment = gql`
  fragment CertificateMiniInfo on Certificate {
    ...CertificateMini
    polish
    colorShade
    symmetry
    labgrown # should be able to perform type check
  }
  ${CertificateMiniFragment}
`;

export const CertificateInfoFragment = gql`
  fragment CertificateInfo on Certificate {
    ...CertificateMiniInfo
    f_color
    f_overtone
    f_intensity
    verified
    length
    width
    depth
    shape
  }
  ${CertificateMiniInfoFragment}
`;

export const CertificateLongInfoFragment = gql`
  fragment CertificateLongInfo on Certificate {
    ...CertificateInfo
    pdfUrl
    depth
    width
    length
    crownAngle
    crownHeight
    pavAngle
    pavHeight
    pavDepth
    table
    depthPercentage
    starLength
    girdle
    lowerGirdle
    culetSize
    labgrown_type
    treated
  }
  ${CertificateInfoFragment}
`;
// Note: earlier BaseCertificateFragment
export const CertificateFragment = gql`
  fragment CertificateFragment on Certificate {
    ...CertificateLongInfo
    keyToSymbols
    comments
    fullShape
    country_of_origin
    canada_mark_eligible
  }
  ${CertificateLongInfoFragment}
`;

export const BaseCertificateFragment = gql`
  fragment BaseCertificate on Certificate {
    f_color
    f_overtone
    f_intensity
    verified
    polish
    colorShade
    symmetry
    labgrown # should be able to perform type check
    id
    certNumber
    cut
    shape
    carats
    color
    clarity
    floCol
    floInt
    lab
  }
`;
