import { gql } from '@apollo/client';

export let GemstoneCertificate = gql`
  fragment GemstoneCertificate on GemCertificate {
    id
    certNumber
    cut
    colorShade
    color
    carats
    shape
    clarity
    gemType
    v360 {
      top_index
      url
      frame_count
    }
    image
    video
    lab
    country_of_origin
    shape
    length
    width
    depth
    comments
    pdfUrl
    verified
    treatment
    pieces
    nivoda_curated
    gallery_link
  }
`;
export let GemstoneFragment = gql`
  fragment GemstoneFragment on Gemstone {
    id
    supplierStockId
    gemstoneSupplierStockId
    availability
    mine_of_origin
    supplier {
      id
      name
      is_owner
      city
      country
      accept_returns
      supplier_status
      company_settings {
        CompanyId
        accept_returns
        accept_holds
        holds_enabled
        hold_hours
        self_serve_po
      }
    }
    location {
      id
      country
    }
    price
    final_price

    offer {
      id
      ProductType
      price # Delivered Price
      return_price # Return Price
      product_price # Gemstone Price
      is_returnable
    }
    delivery_time {
      min_business_days
      max_business_days
    }
    GemstoneCertificate: certificate {
      id
      ...GemstoneCertificate
    }
  }
  ${GemstoneCertificate}
`;

export const GemstoneFragmentForStockIdSearch = gql`
  fragment GemstoneFragmentForStockIdSearch on Gemstone {
    ...GemstoneFragment
    QcGemstone {
      id
      status
    }
  }
  ${GemstoneFragment}
`;

export const GemstoneOfferFragment = gql`
  fragment GemstoneOfferFragment on GemstoneOffer {
    id
    price # Delivered Price
    return_price # Return Price
    product_price # Gemstone Price
    is_returnable
    hold {
      id
      status
      buyer {
        id
        firstName
        company {
          id
          name
        }
      }
      deny_reason
      extension_until
      denied
      until
    }
    Product {
      ... on Gemstone {
        ...GemstoneFragment
      }
    }
    ProductType
  }
  ${GemstoneFragment}
`;
// supplier {
//   id
// }
