import styled, { keyframes } from 'styled-components';
import { styled_predicate } from 'helpers';
import { theme } from 'fabric/theme';
export const CarouselWrapper = styled.div`
  .inline_crousel {
    overflow-x: hidden;
  }
  .popup {
    padding: 0;
    margin: 0;
    border-radius: 8px;
    max-height: 90vh;
  }
  .close-icon {
    cursor: pointer;
  }
  /* .content-area {
    } */
  .popup_content {
    padding: 0;
  }
  .reset-button {
    color: ${theme.palette.gray800};
    ${theme.fonts.smallBold};
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid #e4e4e7;
    border-radius: 5px;
    :hover {
      color: white;
      svg {
        color: white;
      }
    }
    svg {
      margin-right: 6px;
    }
  }
  .eyeclean-cert {
    display: grid;
    gap: 4px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .more-media {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: ${theme.spacing.s1};
    padding-left: ${theme.spacing.s1};
    font-size: 10px;
    font-weight: 500;
    &-label {
      text-decoration: underline;
      &:hover {
        color: ${theme.palette.themeSecondary};
        span,
        svg {
          color: ${theme.palette.themeSecondary};
        }
      }
    }
  }
  .zoom-component {
    position: absolute;
    bottom: 0;
    left: 20px;
    /* margin: 0 auto; */
    right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .cert-center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
  .inline_crousel {
    /* width: 665px; */
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    .close_button_box {
      position: absolute;
      right: 20px;
      top: 15px;
      .close_button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 16px;
        gap: 12px;
        width: 100px;
        height: 40px;
        border: 1px solid #0c0a09;
        border-radius: 5px;
        font-weight: 700;
        font-size: 14px;
        color: #0c0a09;
        background-color: transparent;
      }
    }
    .inline_crousel_box {
      ${styled_predicate((p) => p.isDiamond)} {
        padding: 12px !important;
        gap: 10px;
        grid-gap: 10px;
        align-items: center;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      grid-gap: 16px;
      width: 540px;
      /* border: 1px solid black; */
      padding: 24px;
      position: relative;
      .crousel_media_header {
        ${styled_predicate((p) => p.isDiamond)} {
          width: 100%;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 16px;
        height: 24px;
        .left-box {
          padding: 2px 0;
        }
        .right-box {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;
          cursor: pointer;
          .close-button-icon {
            color: #d4d4d8;
          }
          &:hover {
            .close-button-icon {
              color: #000000;
            }
          }
        }
      }
      .crousel_media_image {
        ${styled_predicate((p) => p.isDiamond)} {
          overflow: hidden;
          border-radius: 5px;
          .aws_wrapper {
            height: 520px;
            width: 520px;
          }
        }
        ${styled_predicate((p) => !p.isDiamond)} {
          border: 1px solid #e7e7e7;
          border-radius: 5px;
          padding: 0 10px;
          align-items: center;
        }
        position: relative;
        display: flex;
        justify-content: center;
        .button_group {
          position: absolute;
          left: 17px;
          z-index: 1;
          .button_zoom {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 44px;
            height: 44px;
            background: rgba(228, 228, 228, 0.5);
            cursor: pointer;
          }
        }
        .measurements {
          position: absolute;
          right: 17px;
          height: 160px;
          z-index: 1;
        }
      }
      .crousel_media_thumbes {
        ${styled_predicate((p) => p.isDiamond)} {
          .aws_wrapper {
            width: 60px !important;
            height: 64px !important;
          }
          width: 100%;
        }
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5px;
        flex-wrap: wrap;
        .active-item {
          border: 1px solid #5620e1 !important;
        }
        .crousel_media_item {
          width: 65px;
          height: 65px;
          border: 1px solid #dadada;
          border-radius: 5px;
          /* img {
              border-radius: 5px;
            } */
          cursor: pointer;
          &:hover {
            border: 1px solid #5620e1;
          }
          ${styled_predicate((p) => !p.isDiamond)} {
            .aws_wrapper_medium {
              width: 64px !important;
              height: 64px !important;
            }
          }
        }
      }
      .crousel_media_actions {
        ${styled_predicate((p) => p.isDiamond)} {
          width: 100%;
        }
        display: flex;
        justify-content: space-between;
        gap: 16px;
        flex-direction: row;
        flex-direction: wrap;
        .action_button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px 16px;
          gap: 12px;
          width: 100%;
          height: 40px;
          border: 1px solid #0c0a09;
          border-radius: 8px;
          font-weight: 700;
          font-size: 14px;
          color: #0c0a09;
          background-color: transparent;
        }
      }
    }
  }
`;
const rotateAnimation = keyframes`
  from{
    transform: rotate((0deg));
  }
  to{
    transform: rotate(360deg);
  }
  `;
export const DownloadPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 400px;
  .timer {
    animation-name: ${rotateAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  .download-title {
    ${theme.fonts.largeBold}
  }
  .progress-text {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .action_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;
    width: 100%;
    height: 40px;
    border: 1px solid #0c0a09;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #0c0a09;
    background-color: transparent;
    :hover {
      color: white;
      svg {
        color: white;
      }
    }
  }
  .successWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .successBG {
    display: inline-flex;
    border-radius: 50%;
    background: ${theme.semanticColors.successBG0};
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }
  .errorBG {
    display: inline-flex;
    border-radius: 50%;
    background: ${theme.semanticColors.errorBG0};
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }
  .loadingBG {
    display: inline-flex;
    border-radius: 50%;
    background: ${theme.palette.gray};
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }
`;
