import { Helmet } from 'react-helmet';
import { SignupContainerWrapper } from './styles';
import NewNivodaLogo from 'fabric/resources/svg/new_nivoda_logo.svg';
import { clearCurrentSession } from '../graphql/factory';
import { navigate } from '@reach/router';
import { LanguageChanger } from 'components/language_changer';
import { Button } from 'fabric/components';
import { CustomTrans } from 'helpers';
import { isMobile } from 'utils';
import { NeedHelp } from 'fabric/integrations';
import Header from 'layout/Header';

// import { WithScrollbar } from '@nivoda/ui';

export let SignupContainer = (props) => {
  return (
    <SignupContainerWrapper>
      {isMobile() ? (
        <SignupContainerMobile {...props} />
      ) : (
        <SignupContainerDesktop {...props} />
      )}
    </SignupContainerWrapper>
  );
};

export let SignupContainerDesktop = ({ children }) => {
  // const [toggleSupportWidget] = useToggleZendeskHubspot();
  // const handleOnClick = () => {
  //   toggleSupportWidget();
  // };
  return (
    <div className="signup-wrapper-desktop">
      <Helmet
        title="Register - Nivoda"
        meta={[
          {
            name: 'description',
            content:
              'Register now to get started with Nivoda. Complete your registration in less than 5 minutes and start using the best B2B platform that is the fastest, easiest and most profitable way to buy and sell diamonds',
          },
        ]}
      />
      <div className="sidebar">
        <div className="sidebar--header">
          <img
            alt="Nivoda Logo"
            className="nivoda_logo"
            src={NewNivodaLogo}
            onClick={async () => {
              clearCurrentSession();
              await navigate('https://nivoda.net');
              // handleOnClick();
            }}
          />
          <LanguageChanger is_public />
        </div>
        <div className="sidebar--content">
          <span className="sb_co-title">
            <CustomTrans
              text={'join_the_worlds_largest_diamonds_marketplace'}
              returnPlainText={true}
            />
          </span>
          <p className="sb_co-para">
            <CustomTrans text={'brand_quote'} returnPlainText={true} />
          </p>
          <span className="author">— Alma Pettersson, Vanbruun</span>
        </div>
        <div className="sidebar--footer">
          <div className="already_have_text">
            <CustomTrans
              text={'already_have_nivoda_account'}
              returnPlainText={true}
            />
          </div>
          <Button className="login_button" onClick={() => navigate('/login')}>
            <CustomTrans text={'login'} returnPlainText={true} />
          </Button>
        </div>
      </div>
      <div className="container_content">
        <NeedHelp />
        <div className="container_content--mid">
          <div className="container_content--core">{children}</div>
        </div>
      </div>
    </div>
  );
};

export let SignupContainerMobile = ({ children }) => {
  return (
    <div className="signup-wrapper-mobile">
      <Helmet
        title="Register - Nivoda"
        meta={[
          {
            name: 'description',
            content:
              'Register now to get started with Nivoda. Complete your registration in less than 5 minutes and start using the best B2B platform that is the fastest, easiest and most profitable way to buy and sell diamonds',
          },
        ]}
      />

      <div className="container_content">
        <Header showCreate={false} showHelp={true} is_button={true} />

        <div className="container_content--mid">
          <div className="container_content--core">{children}</div>
        </div>
      </div>
    </div>
  );
};
