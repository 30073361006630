import { isGemstoneItem, isDiamondItem } from 'utils';
import DiamondImage from 'diamond-image/DiamondImage';
import { DiamondBadge } from 'UI/DiamondBadge';
import { PAYMENT_TERMS_AVAILABLE } from './constants';
import { InfoIconWithTooltip, Typography } from './components/CustomUI';
import { CustomTrans, Diamond } from 'helpers';
import theme from 'fabric/theme';
import React from 'react';
import kriyaLogo from 'fabric/resources/svg/kriya_logo.svg';
import { Pill } from 'fabric/components';
import uniq from 'lodash/uniq';
import { Trans } from 'react-i18next';

export let categoriesMapper = (items_in_cart) =>
  uniq(
    items_in_cart?.map((ele) => {
      return ele?.offer.ProductType === 'Diamond'
        ? ele?.offer?.Product?.certificate?.labgrown
          ? 'LabGrownDiamonds'
          : 'NaturalDiamonds'
        : ele?.offer.ProductType === 'Gemstone'
        ? 'Gemstones'
        : ele?.offer.ProductType === 'Melee'
        ? ele?.offer?.Product?.type === 'NATURAL'
          ? 'NaturalMelee'
          : 'LabGrownMelee'
        : '';
    })
  ).join(',');

export const mapItemsToTypes = (items_in_cart = []) => {
  let _items = {
    melee: [],
    diamond: [],
    gemstone: [],
  };
  return items_in_cart.reduce((acc, cart_item) => {
    let { offer, ...rest } = cart_item;
    let item = { ...offer, ...rest };
    let ProductType = item?.ProductType;

    if (!ProductType) return acc;
    let itemAssigned = Object.assign({}, item);
    if (ProductType === 'Diamond') acc.diamond = [...acc.diamond, itemAssigned];
    if (ProductType === 'Gemstone') {
      acc.gemstone = [...acc.gemstone, itemAssigned];
    }
    if (ProductType === 'Melee') {
      itemAssigned = {
        ...itemAssigned,
        Product: {
          ...itemAssigned?.Product,
          certificate: itemAssigned?.Product?.MeleeCertificate,
        },
      };
      acc.melee = [...acc.melee, itemAssigned];
    }

    return acc;
  }, _items);
};

export const DiamondWithBadge = ({
  className = '',
  diamond: product,
  certificate, // gemstone is different
  isAdmin,
  top = 2,
  left = 2,
  showPopup = false,
  isExpressItem = false,
  badge = true,
  ...rest
}) => {
  const is_diamond_type = isDiamondItem(product);
  const is_gemstone_type = isGemstoneItem(product);
  const isMeleeProduct = product?.__typename.includes('Melee');

  const eligible_for_free_delivery = is_diamond_type
    ? isAdmin && product.offer.product_price === product.offer.price
    : false;

  const show_diamond_badge =
    (badge &&
      !is_gemstone_type &&
      (is_diamond_type || isMeleeProduct
        ? certificate?.labgrown
        : product.type !== void 0 && product.type !== 'NATURAL')) ||
    (badge && (is_diamond_type || isMeleeProduct)
      ? certificate?.treated
      : product.treated) ||
    eligible_for_free_delivery;

  return (
    <div className={`__diamond-image ${className}`}>
      <DiamondImage
        diamond={product}
        hidePreview={isMeleeProduct}
        imageUrl={certificate.image}
        url360={certificate.v360}
        shape={certificate.shape}
        small={true}
        onlyImage={true}
        hasRadius
        className={'fill_available'}
        {...rest}
      />
      {show_diamond_badge && (
        <DiamondBadge
          eligible_for_free_delivery={eligible_for_free_delivery}
          is_hpht={
            is_diamond_type || isMeleeProduct
              ? certificate?.treated
              : product.treated
          }
          is_labgrown={
            is_diamond_type || isMeleeProduct
              ? certificate.labgrown
              : product.type === 'LABGROWN'
          }
          tooltip_width={eligible_for_free_delivery ? 70 : 140}
          width={'auto'}
          height={'auto'}
          top={top}
          left={left}
          tooltop_postion={'right'}
          with_tooltip={true}
          view={'LIST'}
          list_icon
        />
      )}
    </div>
  );
};

let no_negative = (price) => {
  return Math.max(+price, 0);
};
export const FormattedPriceToUSDOthers = (price, currency) => {
  return no_negative(price)
    .toLocaleString('en-GB', {
      style: 'currency',
      currency: currency ? currency : 'USD',
      minimumFractionDigits: 2,
    })
    .replace(/US/, '');
};

export const getPaymentTitleOnly = (paymentTerm) => {
  switch (paymentTerm) {
    case PAYMENT_TERMS_AVAILABLE.UPFRONT_PAY:
      return <CustomTrans text={'upfront_payment'} returnPlainText={true} />;
    case PAYMENT_TERMS_AVAILABLE.PAY_IN_30:
      return <CustomTrans text={'pay_in_30_days'} returnPlainText={true} />;
    case PAYMENT_TERMS_AVAILABLE.PAY_IN_60:
      return <CustomTrans text={'pay_in_60_days'} returnPlainText={true} />;
    case PAYMENT_TERMS_AVAILABLE.PAY_IN_90:
      return <CustomTrans text={'pay_in_90_days'} returnPlainText={true} />;
    case PAYMENT_TERMS_AVAILABLE.CREDIT_3_DAY:
      return <CustomTrans text={'pay_in_3_days'} returnPlainText={true} />;
    default:
  }
};

export const getPaymentTitle = ({
  paymentTerm,
  paymentDetails = { discount_amount: 0, interest_rate: 0 },
  isFullDetails = false,
  showToolTip = false,
  isListing = false,
  is_kriya_cx = false,
  isMemoWalletCheckout = false,
  memoDuration,
  isDisplayMemoDuration = false,
  vatRate,
  all_in_pricing,
}) => {
  const CreditsDisplay = ({ text, showFree = true }) => {
    return (
      <React.Fragment>
        <Typography variant={'h6'} color={theme.palette.themePrimary}>
          {text}{' '}
        </Typography>

        {isFullDetails && (
          <>
            {text === 'pay_in_30_days' && (
              <Pill
                content={
                  <CustomTrans text={'recommended'} returnPlainText={true} />
                }
                variant="primary"
                mode={isMemoWalletCheckout ? 'memo' : 'default'}
              />
            )}
            {showFree && Number(paymentDetails?.discount_amount) === 0 && (
              <Pill
                content={<CustomTrans text={'free'} returnPlainText={true} />}
                variant="success"
              />
            )}
            {paymentDetails?.interest_rate !== 0 &&
              paymentDetails?.interest_rate != null && (
                <Pill
                  content={`+${paymentDetails?.interest_rate.toFixed(2)}%${
                    vatRate && all_in_pricing ? ' + Tax' : ''
                  }`}
                  variant={
                    paymentDetails?.interest_rate.toString().startsWith('-')
                      ? 'success'
                      : 'default'
                  }
                />
              )}
            {is_kriya_cx && <img src={kriyaLogo} alt="logo" />}
          </>
        )}
        {showToolTip && (
          <InfoIconWithTooltip tooltip_props={{ content: '' }} iconSize={15} />
        )}
      </React.Fragment>
    );
  };
  switch (paymentTerm) {
    case PAYMENT_TERMS_AVAILABLE.UPFRONT_PAY:
      return (
        <React.Fragment>
          <Typography variant={'h6'}>
            <CustomTrans text={'upfront_payment'} returnPlainText={true} />{' '}
          </Typography>
          {isFullDetails && (
            <>
              <Pill
                content={
                  <CustomTrans
                    text={'shipping_after_payment'}
                    returnPlainText={true}
                  />
                }
                variant="warning"
              />
              {paymentDetails?.interest_rate !== 0 &&
                paymentDetails?.interest_rate != null && (
                  <Pill
                    content={`${paymentDetails?.interest_rate?.toFixed(2)}%${
                      vatRate && all_in_pricing ? ' + Tax' : ''
                    }`}
                    variant={
                      paymentDetails?.interest_rate?.toString().startsWith('-')
                        ? 'success'
                        : 'default'
                    }
                  />
                )}
              {Number(paymentDetails?.discount_amount) === 0 && (
                <Pill
                  content={<CustomTrans text={'free'} returnPlainText={true} />}
                  variant="success"
                />
              )}
              {isListing && (
                <>
                  <Pill
                    content={
                      <CustomTrans
                        text={'recommended'}
                        returnPlainText={true}
                      />
                    }
                    variant="primary"
                    mode={isMemoWalletCheckout ? 'memo' : 'default'}
                  />
                </>
              )}
            </>
          )}
          {showToolTip && (
            <InfoIconWithTooltip
              tooltip_props={{ content: '' }}
              iconSize={15}
            />
          )}
        </React.Fragment>
      );
    case PAYMENT_TERMS_AVAILABLE.PAY_IN_30:
      return (
        <CreditsDisplay
          showFree={false}
          text={
            <Trans
              i18nKey={
                isMemoWalletCheckout
                  ? isDisplayMemoDuration
                    ? 'pay_after_memo_period'
                    : 'pay_days'
                  : 'pay_in_30_days'
              }
              values={{ days: '30', memoDuration: memoDuration }}
              components={{ strong: <strong /> }}
            />
          }
          returnPlainText
        />
      );
    case PAYMENT_TERMS_AVAILABLE.PAY_IN_60:
      return (
        <CreditsDisplay
          text={
            <Trans
              i18nKey={
                isMemoWalletCheckout
                  ? isDisplayMemoDuration
                    ? 'pay_after_memo_period'
                    : 'pay_days'
                  : 'pay_in_60_days'
              }
              values={{ days: '60', memoDuration: memoDuration }}
              components={{ strong: <strong /> }}
            />
          }
          returnPlainText
        />
      );
    case PAYMENT_TERMS_AVAILABLE.PAY_IN_90:
      return (
        <CreditsDisplay
          text={
            <Trans
              i18nKey={
                isMemoWalletCheckout
                  ? isDisplayMemoDuration
                    ? 'pay_after_memo_period'
                    : 'pay_days'
                  : 'pay_in_90_days'
              }
              values={{ days: '90', memoDuration: memoDuration }}
              components={{ strong: <strong /> }}
            />
          }
          returnPlainText
        />
      );
    // return <Credits />;
    case PAYMENT_TERMS_AVAILABLE.CREDIT_3_DAY:
      return (
        <React.Fragment>
          <Typography variant={'h6'}>
            <Trans
              i18nKey={
                isMemoWalletCheckout
                  ? isDisplayMemoDuration
                    ? 'pay_after_memo_period'
                    : 'pay_days'
                  : 'pay_in_3_days'
              }
              values={{ days: '3', memoDuration: memoDuration }}
              components={{ strong: <strong /> }}
            />{' '}
          </Typography>

          {isFullDetails && (
            <>
              {paymentDetails?.interest_rate !== 0 &&
                paymentDetails?.interest_rate != null && (
                  <Pill
                    content={`${paymentDetails?.interest_rate.toFixed(2)}%${
                      vatRate && all_in_pricing ? ' + Tax' : ''
                    }`}
                    variant={
                      paymentDetails?.interest_rate?.toString().startsWith('-')
                        ? 'success'
                        : 'default'
                    }
                  />
                )}
              {Number(paymentDetails?.discount_amount) === 0 && (
                <Pill
                  content={<CustomTrans text={'free'} returnPlainText={true} />}
                  variant="success"
                />
              )}
            </>
          )}
          {showToolTip && (
            <InfoIconWithTooltip
              tooltip_props={{ content: '' }}
              iconSize={15}
            />
          )}
        </React.Fragment>
      );
    default:
      return '';
  }
};

export const deliverToMultipleAddress = (items) => {
  const location_id = items && items?.length > 0 && items[0].locationId;
  // if found first different location kill the loop
  // and show deliver order items to multiple address
  return !!items.find(({ locationId }) => locationId !== location_id);
};

export const isImpureEyeCleanStone = (item) => {
  if (!item) return null;

  const Product = item.Product != null ? item.Product : item;
  if (!Product) return null;
  const eyeclean = Diamond.get_eyeclean(Product);
  return (eyeclean && Number(eyeclean.slice(0, -1)) < 100) || eyeclean === 'No';
};

export const isMemoWalletCheckoutPath = (path) =>
  path.includes('memo-wallet-checkout');

export const baseCheckoutPath = (isMemoWalletCheckout) =>
  `/v2/live/${isMemoWalletCheckout ? 'memo-wallet-' : ''}checkout`;
